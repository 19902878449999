/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_appsync_graphqlEndpoint:
    'https://4e5mzscq2fcc3mq24w363utrvq.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-7lwjs3qx4bbylmzd4slcaxllvi',
  aws_cognito_identity_pool_id:
    'us-west-2:7274bc93-9075-4e16-a54c-996e617349e1',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_sfEOlQ38m',
  aws_user_pools_web_client_id: '34josoh843n0kers9g67t66rvs',
  oauth: {
    domain: 'hostffa29f3f-ffa29f3f-staging.auth.us-west-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'hosttravel://',
    redirectSignOut: 'hosttravel://',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL', 'NAME'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
      'REQUIRES_UPPERCASE',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'host-media05309-staging',
  aws_user_files_s3_bucket_region: 'us-west-2',
};

export default awsmobile;
