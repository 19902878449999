import styles from "./Footer.module.css";
import appIcon from "../../assets/official_app_icon.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className={`align-items-center ${styles.footer}`}>
      <div className={`${styles.footerOuter}`}>
        <span className={`${styles.mdBlackDefault}`}>
          © 2023 Host Inc. ·{" "}
          <Link to="/terms">
            <span className={`${styles.headerLink} ${styles.mdBlackDefault}`}>
              Terms
            </span>
          </Link>{" "}
          ·{" "}
          <Link to="/privacy">
            <span className={`${styles.headerLink} ${styles.mdBlackDefault}`}>
              Privacy
            </span>
          </Link>
        </span>
      </div>
      <div className={`${styles.footerInner}`}>
        <span
          className={`${styles.gradientText}`}
          style={{ marginRight: "10px" }}
        >
          our mobile app is arriving on Google Play and the App Store soon
        </span>
        <img src={appIcon} alt="H" className={`img-fluid ${styles.logo}`} />
      </div>
      <div className={`d-flex justify-content-end ${styles.footerOuter}`}>
        <span className={`${styles.mdBlackDefault}`}>
          <Link to="/about">
            <span className={`${styles.headerLink} ${styles.mdBlackDefault}`}>
              About
            </span>
          </Link>{" "}
          ·{" "}
          <Link to="/support">
            <span className={`${styles.headerLink} ${styles.mdBlackDefault}`}>
              Support
            </span>
          </Link>
        </span>
      </div>
    </div>
  );
};