import { Spinner } from "react-bootstrap";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { DEFAULT_USER_IMAGE } from "../../config";
import colors from "../../theme/colors";

interface IUserImage {
  imageKey?: string | null;
  style?: object;
}

const UserImage = ({ imageKey, style }: IUserImage) => {
  const [imageURI, setImageURI] = useState<string | null>(null);

  useEffect(() => {
    if (imageKey) {
      Storage.get(imageKey).then(setImageURI);
    }
  }, [imageKey]);

  if (!imageKey) {
    return <img style={style} src={DEFAULT_USER_IMAGE} />;
  } else {
    if (!imageURI) {
      return (
        <div
          style={{
            ...style,
            justifyContent: "center",
            alignItems: "center",
            borderWidth: "0.5px",
            borderColor: colors.gray,
          }}
        >
          <Spinner
            animation="border"
            style={{
              borderColor: colors.primary,
              borderRightColor: "transparent",
            }}
          />
        </div>
      );
    } else {
      return <img style={style} src={imageURI} alt="Image" />;
    }
  }
};

export default UserImage;
