import "bootstrap/dist/css/bootstrap.min.css";
import Client from "./apollo/Client";
import { Amplify } from "@aws-amplify/core";
import awsconfig from "./aws-exports";
import AuthContextProvider from "./contexts/AuthContext";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop/ScrollToTop";
import { Home } from "./pages/Home/HomePage";
import { About } from "./pages/About/AboutPage";
import { Terms } from "./pages/Terms/TermsPage";
import { Privacy } from "./pages/Privacy/PrivacyPage";
import { Support } from "./pages/Support/SupportPage";
import { SignIn } from "./pages/Auth/SignInPage/SignInPage";
import { SignUp } from "./pages/Auth/SignUpPage/SignUpPage";
import { ConfirmEmail } from "./pages/Auth/ConfirmEmailPage/ConfirmEmailPage";
import { ForgotPassword } from "./pages/Auth/ForgotPasswordPage/ForgotPasswordPage";
import { NewPassword } from "./pages/Auth/NewPasswordPage/NewPasswordPage";
import { useAuthContext } from "./contexts/AuthContext";
import { Search } from "./pages/Search/SearchPage";
import { ListingDetails } from "./pages/ListingDetails/ListingDetailsPage";

Amplify.configure(awsconfig);

function App() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  if (user) {
    navigate("/search");
  }

  return (
    <AuthContextProvider>
      <Client>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirmemail/:username" element={<ConfirmEmail />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/newpassword" element={<NewPassword />} />

          <Route path="/search" element={<Search />} />
          <Route path=":id">
            <Route index element={<ListingDetails />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Client>
    </AuthContextProvider>
  );
}

export default App;
