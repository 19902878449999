import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Stack, Button, Form } from "react-bootstrap";
import styles from "./NewPassword.module.css";
import { FormInput } from "../components/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

type NewPasswordType = {
  username: string;
  code: string;
  password: string;
};

export const NewPassword = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<NewPasswordType>();
  const [loading, setLoading] = useState(false);

  const isPasswordValid = (password: string): boolean => {
    if (password.length < 8) {
      return false;
    }
    if (!/[a-z]/.test(password)) {
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      return false;
    }
    if (!/[0-9]/.test(password)) {
      return false;
    }
    if (!/[^a-zA-Z0-9]/.test(password)) {
      return false;
    }
    return true;
  };

  const onSubmitPressed = async ({
    username,
    code,
    password,
  }: NewPasswordType) => {
    if (loading) {
      return;
    }
    if (!isPasswordValid(password)) {
      alert("Password does not meet policy requirements.");
      return;
    }
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      navigate("/signin");
      alert(`The password for ${username} has been reset`);
    } catch (e) {
      alert(`Ooops, ${(e as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack className={`${styles.authContainer} mx-auto`} direction="vertical">
      <span className={`${styles.title}`}>Reset your Password</span>

      <Form onSubmit={handleSubmit(onSubmitPressed)}>
        <FormInput
          placeholder="Username"
          name="username"
          control={control}
          rules={{ required: "Username is required" }}
        />

        <FormInput
          placeholder="Code"
          name="code"
          control={control}
          rules={{ required: "Code is required" }}
        />

        <FormInput
          placeholder="Enter your new password"
          name="password"
          control={control}
          secureTextEntry
          rules={{
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password should be at least 8 characters long",
            },
          }}
        />

        <Button className={`${styles.button}`} type="submit">
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Form>

      <div className={`${styles.buttonContainer}`}>
        <Link to="/signin">
          <span className={`${styles.buttonText}`}>Back to Sign In</span>
        </Link>
      </div>
    </Stack>
  );
};