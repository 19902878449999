import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Stack, Button, Form } from "react-bootstrap";
import styles from "./ConfirmEmail.module.css";
import { FormInput } from "../components/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

type ConfirmEmailData = {
  username: string;
  code: string;
};

export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const { control, handleSubmit, watch } = useForm<ConfirmEmailData>({
    defaultValues: { username: username },
  });
  const [loading, setLoading] = useState(false);

  const user = watch("username");

  const onConfirmPressed = async ({ username, code }: ConfirmEmailData) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await Auth.confirmSignUp(username, code);
      navigate("/signin");
      alert("Your email has been verified!");
    } catch (e) {
      alert(`Ooops, ${(e as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  const onResendPress = async () => {
    try {
      await Auth.resendSignUp(user);
      alert("Check your email, the code has been sent");
    } catch (e) {
      alert(`Ooops, ${(e as Error).message}`);
    }
  };

  return (
    <Stack className={`${styles.authContainer} mx-auto`} direction="vertical">
      <span className={`${styles.title}`}>Confirm your Email</span>

      <Form onSubmit={handleSubmit(onConfirmPressed)}>
        <FormInput
          name="username"
          control={control}
          placeholder="Username"
          rules={{
            required: "Username is required",
          }}
        />

        <FormInput
          name="code"
          control={control}
          placeholder="Enter your confirmation code"
          rules={{
            required: "Confirmation code is required",
          }}
        />

        <Button className={`${styles.button}`} type="submit">
          {loading ? "Loading..." : "Confirm"}
        </Button>
      </Form>

      <Button
        className={`${styles.otherButton}`}
        onClick={() => onResendPress()}
      >
        Resend code
      </Button>

      <div className={`${styles.buttonContainer}`}>
        <Link to="/signin">
          <span className={`${styles.buttonText}`}>Back to Sign In</span>
        </Link>
      </div>
    </Stack>
  );
};
