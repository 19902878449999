import { Row, Col, Container, Stack } from "react-bootstrap";
import logoOrange from "../../assets/host_logo_negative_orange.png";
import styles from "./AboutPage.module.css";
import { Footer } from "../../components/Footer/Footer";

export function About() {
  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <Row xs={3} className={`align-items-center mb-4 ${styles.header}`}>
        <Col>
          <Row>
            <div className="d-flex align-items-center">
              <div className="m-2">
                <img
                  onClick={() => window.history.back()}
                  src={logoOrange}
                  alt="H"
                  className={`img-fluid ${styles.logo} ${styles.logoContainer}`}
                />
              </div>

              {["A", "b", "o", "u", "t"].map((letter, index) => (
                <div key={index} onClick={() => window.history.back()}>
                  <span className={`${styles.logoLetter}`}>{letter}</span>
                </div>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
      {/* Body */}
      <div className="flex-grow-1 d-flex justify-content-center">
        <Container fluid>
          <Stack gap={2} direction="vertical" className={`align-items-center`}>
            <div className={`${styles.titleContainer}`}>
              <span className={`${styles.title}`}>3 Country Boys</span>
              <span className={`${styles.title}`}>with a Mission</span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>
                Meet the Makers of HOST: Where Travel Meets Adventure
              </span>
              <span className={`${styles.subText}`}>
                At HOST, we're driven by the spirit of adventure and the love of
                open spaces, brought to life by three kindred souls—Caleb,
                Soren, and Ben. These three country boys share more than a
                mission; they share a lifelong connection to rural life and a
                deep appreciation for the great outdoors.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>Where It All Began</span>
              <span className={`${styles.subText}`}>
                It all began when two of our founders, Caleb and Soren, faced
                the high costs of KOA campgrounds and decided there had to be a
                better solution. Their idea was simple yet brilliant: why not
                open their properties to fellow travelers, offering a more
                affordable, safer, and exciting way to explore the world?
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>A Vision Takes Root</span>
              <span className={`${styles.subText}`}>
                It was this idea that they brought to their neighbor, Ben, an
                engineer. Just like them, Ben was raised on a farm. The idea
                resonated with him, and he saw the potential to make something
                extraordinary happen.
              </span>
              <span className={`${styles.subText}`}>
                Together, they took their shared dream, crafted a plan, and
                brought HOST to life.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>
                More Than a Marketplace
              </span>
              <span className={`${styles.subText}`}>
                HOST is more than just a marketplace; it's a community of hosts
                and guests dedicated to making travel an unforgettable
                experience. Our listings span various categories, including
                "Camping" for RVs, motorhomes, tents, and more, and "Rodeo" for
                travelers with livestock, be it horses, cows, sheep, goats, or
                other farm animals.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>
                A Place for Every Adventurer
              </span>
              <span className={`${styles.subText}`}>
                Our audience includes a diverse range of travelers: from those
                exploring the open road in their RVs to adventurers pitching
                tents in scenic spots. We also cater to anyone traveling with
                livestock, ensuring that your animal companions have a safe and
                comfortable place to stay.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>
                The Exclusive Offering
              </span>
              <span className={`${styles.subText}`}>
                What sets HOST apart is that we're the only platform where you
                can book both camping and rodeo listings on private land. Our
                commitment to transparency is unmatched, with ZERO hidden fees
                or additional charges at checkout (except for state taxes). We
                believe in providing you with straightforward and honest
                information about your travel expenses, so you can travel with
                peace of mind.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>
                Privacy and Security at the Core
              </span>
              <span className={`${styles.subText}`}>
                At HOST, your privacy and security are our top priorities. We do
                not sell information and do not disclose any form of your
                personal information. We believe in your right to travel and
                host with confidence, knowing you're in a safe and secure
                environment.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>
                3 Country Boys, 1 American Dream
              </span>
              <span className={`${styles.subText}`}>
                Caleb, Soren, and Ben are three country boys on a mission to
                offer safe, secure, affordable, convenient, and exciting travel
                opportunities. We don't sell your information and take your
                privacy seriously. We're proud Americans united in our
                commitment to freedom and truth. And we will forever strive to
                build strong community and culture in this incredible country of
                ours.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>The Road Ahead</span>
              <span className={`${styles.subText}`}>
                In the coming years, our vision is to expand from the Northwest
                to all 50 states. We aim to broaden our offerings to include
                camping sites in state parks, national parks, and major music
                and entertainment festivals. In the near future we plan to
                introduce "Experiences," where you can rent fields for a day of
                hunting, fishing spots, guided tours, cooking classes, and more.
                Permanent residences (homes, apartments, condos, etc.) for
                short- and long-term stays are also on the horizon.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>
                Join Us on This Adventure
              </span>
              <span className={`${styles.subText}`}>
                Though our site is brand new and officially launching soon,
                we're dedicated to building a resolute community. Your privacy,
                safety, and security are our utmost priorities. Join us on this
                exciting journey as we redefine travel and build a community
                that shares our values of freedom, privacy, and adventure.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>Contact Us</span>
              <span className={`${styles.subText}`}>
                Have questions or need assistance? Reach out to us at
                support@hosttravel.org or call our support line. Follow us on
                Instagram, Facebook, and Twitter (X) for updates and news.
              </span>
            </div>

            <div className={`${styles.subSection}`}>
              <span className={`${styles.subTitle}`}>Travel the HOST Way</span>
              <span className={`${styles.subText}`}>
                Discover a new way to travel where adventure, privacy, and
                community await. HOST isn't just a platform; it's a mission to
                reimagine travel as we know it.
              </span>
            </div>
          </Stack>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
