import {gql} from '@apollo/client';

export const getListing = gql`
  query GetListing($id: ID!) {
    getListing(id: $id) {
      id
      hostID
      Host {
        id
        UserListings {
          items {
            id
            hostID
            listingType
            campType
            rodeoType
            city
            title
            images
            basePrice
            surgePrice
            weeklyDiscount
            monthlyDiscount
            Calendar {
              id
              name
              publisherID
              unavailableDates
              surgePriceDates
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        username
        firstName
        lastName
        email
        emailVerified
        image

        nofListings
        nofStays
        nofNights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ListingReviews {
        items {
          id
          reviewerID
          listingID
          Reviewer {
            id
            username
            firstName
            lastName
            email
            emailVerified
            image

            nofListings
            nofStays
            nofNights
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          rating
          review
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      ListingReservations {
        items {
          id
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      listingType
      campType
      rodeoType
      address
      city
      state
      country
      zip
      latitude
      longitude
      automobile
      bigRig
      fifthWheel
      motorhome
      motorhomeTowing
      pickupCamper
      tent
      tentTrailer
      travelTrailer
      van
      otherCamp
      bovine
      caprine
      equine
      swine
      anyAnimals
      maxGuests
      maxLength
      maxWidth
      maxAnimals
      barn
      pasture
      pen
      shelter
      campPower
      campWater
      sewage
      firewood
      wifi
      cableTV
      rodeoPower
      rodeoWater
      feed
      stalls
      bedding
      specialServices
      surveillance
      smoking
      campfires
      pets
      party
      curfew
      additionalRules
      requiredEmail
      requiredMessage
      title
      description
      images
      basePrice
      surgePrice
      weeklyDiscount
      monthlyDiscount
      cancellation
      bookingInAdvance
      checkInTimeLower
      checkInTimeUpper
      checkOutTime
      Calendar {
        id
        name
        publisherID
        unavailableDates
        surgePriceDates
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bookedDates
      nofReservations
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createSave = gql`
  mutation CreateSave(
    $input: CreateSaveInput!
    $condition: ModelSaveConditionInput
  ) {
    createSave(input: $input, condition: $condition) {
      id
      userID
      listingID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteSave = gql`
  mutation DeleteSave(
    $input: DeleteSaveInput!
    $condition: ModelSaveConditionInput
  ) {
    deleteSave(input: $input, condition: $condition) {
      id
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const savesByListing = gql`
  query SavesByListing(
    $listingID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savesByListing(
      listingID: $listingID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        listingID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getUser = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstName
      lastName
      email
      emailVerified
      image
      termsAccepted
      nofListings
      nofStays
      nofNights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;