import { Row, Col, Container, Stack } from "react-bootstrap";
import logoOrange from "../../assets/host_logo_negative_orange.png";
import styles from "./TermsPage.module.css";
import { Footer } from "../../components/Footer/Footer";

export function Terms() {
  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <Row xs={3} className={`align-items-center mb-4 ${styles.header}`}>
        <Col>
          <Row>
            <div className="d-flex align-items-center">
              <div className="m-2">
                <img
                  onClick={() => window.history.back()}
                  src={logoOrange}
                  alt="H"
                  className={`img-fluid ${styles.logo} ${styles.logoContainer}`}
                />
              </div>

              {["T", "e", "r", "m", "s"].map((letter, index) => (
                <div key={index} onClick={() => window.history.back()}>
                  <span className={`${styles.logoLetter}`}>{letter}</span>
                </div>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
      {/* Body */}
      <div className="flex-grow-1 d-flex justify-content-center">
        <Container fluid>
          <Stack gap={2} direction="vertical" className={`align-items-center`}>
            <div className={`${styles.titleContainer}`}>
              <span className={`${styles.title}`}>Terms Of Service</span>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  These Terms and Conditions (or “Terms”) for the mobile
                  application (“App”) provided by Host, Inc. (“Host,” “us” or
                  “we”) are a binding contract between you and Host. These Terms
                  take effect on the first day you access or download the App
                  and will remain in effect as long as you use the App or the
                  App is downloaded to your device. You acknowledge that we may
                  make changes to the App or these Terms at any time.
                </span>
              </div>

              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  THESE TERMS INCLUDE A CLASS ACTION WAIVER AND AN ARBITRATION
                  PROVISION THAT GOVERNS ANY DISPUTES BETWEEN YOU AND HOST.
                </span>
              </div>

              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  If you have any questions regarding the App or these Terms,
                  please contact us at{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>
                  .
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  1. The App
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  The App connects individuals seeking land for camping,
                  livestock grazing, recreation, or other activities (“Guests”)
                  with listings of land or other real property available for one
                  or more of those activities (“Listings”) owned by hosting
                  landowners offering Listing for such use (“Listing Hosts”).
                  The App allows Guests to make reservations for access to and
                  use of Listings for specified purposes in accordance with the
                  terms provided by the Listing Host (each, a “Reservation”).
                  These Terms apply to Guests, Listing Hosts, and visitors to
                  the App. Additional, separate terms and conditions may apply
                  to certain users or uses of the App.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  2. Acceptance
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  By downloading or installing the App or using the App in any
                  manner, you accept and consent to these Terms and all
                  documents incorporated herein by reference. You can also
                  accept the Terms by clicking to accept or agree to the Terms
                  where this option is made available to you in any agreement or
                  electronic form, by creating an account, or by clicking “sign
                  up” or any similar mechanism. You also acknowledge that you
                  have read and understand our Privacy Policy, incorporated
                  herein by this reference. If you do not agree to these Terms,
                  do not access or use the App.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  3. Eligibility
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  To use the App, you must (a) be at least 18 years of age; (b)
                  reside in a jurisdiction where the App is offered and where it
                  is lawful to use; and (c) represent that you have read,
                  understood, and agree to be bound by the Terms, regardless of
                  the device or platform by which you access the App. You may
                  not access or use the App if you are barred from receiving
                  such services under applicable law or have previously been
                  suspended or removed from any of our products or services. By
                  using the App, you represent and warrant that you meet all of
                  the foregoing eligibility requirements. If you do not meet
                  these eligibility requirements or if you do not agree to these
                  Terms, you may not use the App.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  4. Your Profile
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You must sign up and create a user profile on the App. You
                  agree to only submit information that is true, accurate,
                  current, and complete and to maintain and promptly update your
                  information as needed. You are responsible for maintaining the
                  confidentiality of your login information, and you are
                  responsible for all activities that occur using your access
                  credentials. Some Services may not be available to all users.
                  You agree to notify us immediately of any unauthorized access
                  to or use of your username or password or any other breach of
                  security. Host is not liable for any loss or damages caused by
                  your failure to maintain the confidentiality of your account
                  credentials or your choice to share any data associated with
                  your account with others.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Host reserves the right to require a user to provide proof of
                  their identity. Host may suspend or revoke access to the App
                  at any time if a user fails to provide an acceptable form of
                  ID or if Host is unable to verify the user’s identity.
                  Identity verification on the Internet is difficult and Host
                  cannot, and we do not, assume any responsibility for the
                  confirmation of an App user's purported identity. YOU
                  ACKNOWLEDGE AND AGREE THAT (I) HOST SHALL HAVE NO LIABILITY TO
                  YOU OR ANY OTHER PARTY FOR ANY UNAUTHORIZED TRANSACTION MADE
                  USING ANY AN IDENTIFICATION, ACCOUNT, OR LOGIN CREDENTIAL ON
                  THE APP AND (II) THE UNAUTHORIZED USE OF YOUR IDENTIFICATION,
                  ACCOUNT, OR LOGIN CREDENTIALS OR YOUR EMAIL ACCOUNT COULD
                  CAUSE YOU TO INCUR LIABILITY TO HOST OR OTHER USERS.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  5. License Grant
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Upon our creation of a profile on the App and acceptance of
                  these Terms, Host grants you a limited, non-exclusive,
                  nontransferable, non-sublicensable, revocable license to
                  access, download, install, and use the App for your own
                  personal, non-commercial purposes, subject to these Terms. The
                  App are licensed to you, not sold. Nothing in these Terms is
                  intended to, or may be construed as, conferring by
                  implication, estoppel, or otherwise any ownership, license, or
                  other grant of right to any copyright, trademark, or other
                  intellectual property of Host or any third party, except as
                  expressly provided in these Terms. We reserve all rights not
                  expressly granted in these Terms.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  6. Using Host
                </span>
              </div>

              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>a) Reservations:</span>{" "}
                  Your ability to make a Reservation for any Listing is subject
                  to: (i) availability of the Listing; and (ii) payment of all
                  fees and charges incurred in reserving and/or using the
                  Listing. Unless otherwise agreed in writing, you must settle
                  all fees and charges incurred in reserving and/or using the
                  Listing prior to your use of the Listing. A Reservation is not
                  confirmed until you have received written confirmation of the
                  same through the App. All communications between Listing Hosts
                  and Guests must take place on the App. Host has no liability
                  and will not intervene with respect to any communication or
                  transaction outside of the App. Host may at any time require
                  that you agree to supplemental terms and conditions prior to
                  reserving certain types of Listing.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>b) Listing Rules:</span>{" "}
                  You agree to comply with all rules and limitations set by the
                  Listing Host for the Listing you select for your Reservation
                  (“Listing Rules”) and applicable laws, rules, regulations, and
                  local ordinances in connection with your use of the App and
                  any Reservation. Listing Rules may be communicated to you via
                  the App, by email, by posting signs or notices at the Listing
                  or otherwise. You understand that a violation of Listing Rules
                  may result in cancellation of your Reservation, being denied
                  access to any Listing, or your suspension or barring from the
                  App.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>c) Use of Listings:</span>{" "}
                  You expressly agree to only engage in activities permitted by
                  the Listing Host for the Listing for which you have a
                  Reservation. If you engage in any activity on a Listing that
                  requires a license, certificate, or other approval, you agree
                  that you will at all times during your Reservation and/or use
                  of the Listing hold all necessary licenses, certificates,
                  and/or approvals issued by the appropriate governmental or
                  private authority for such activity. You are strictly
                  prohibited from engaging in any activity that is not permitted
                  by the Listing Host for that Listing or that violates any law,
                  regulation, or rule applicable in the jurisdiction where the
                  Listing is located. YOUR USE OF THE APP AND ANY LISTING IS AT
                  YOUR OWN RISK.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    d) Damage to Listing:
                  </span>{" "}
                  You are responsible for leaving the Listing in the condition
                  it was in when you arrived. You acknowledge and agree that you
                  are responsible for: (i) your own acts and omissions in
                  connection with any Listing; and (ii) the acts and omissions
                  of any third parties to whom you provide access to the
                  Listing, including without limitation any guests or invitees.
                  You hereby authorize us to your payment method for any costs
                  we incur as a result of a violation of Listing Rules or any
                  property damage or physical injury you cause.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    e) Listing Host Responsibilities:
                  </span>{" "}
                  As a Listing Host, you agree to obtain and maintain any and
                  all licenses, certifications, or approvals necessary or
                  required by the local laws and regulations where the Listing
                  is located for short-term or transient lodging, grazing,
                  camping, sports, outdoor recreation, or as otherwise required
                  to post the Listing, accept Reservations, receive Listing
                  Payments, or engage in activities for which you promote the
                  Listing. The Listing Host is solely responsible for responding
                  to and managing any incidents or issues that may arise related
                  to a Guest reservation or use of a Listing according to
                  applicable local law, including without limitation requesting
                  the involvement of law enforcement or other government agency.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>f) Listing Details:</span>{" "}
                  Each Listing Host is responsible for posting accurate
                  information, rules, photos, and other content to describe and
                  promote their Listing(s). Listing Hosts are solely responsible
                  for the accuracy of their Listings, including but not limited
                  to representations about amenities, location, permitted or
                  appropriate use of the Listings, price, and availability. Host
                  does not represent or warrant that any of the copy, content,
                  reviews, or other information contained in a Listing is
                  accurate or up to date. Host expressly disclaims any and all
                  liability arising from alleged accuracy of the Listings,
                  reviews, or any alleged breaches of contract on a Listing
                  Host’s part. We have no duty to prescreen the content of any
                  Listing, reviews, or other content posted to the App by any
                  user.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    g) Reservations are Temporary:
                  </span>{" "}
                  A Reservation is a temporary grant of access to a Listing for
                  a limited term, and shall not be considered to convey a
                  tenancy, leasehold, ownership interest, or any right of access
                  in and to the Listing beyond the term for which a Guest
                  initially made such Reservation. As a Guest, you acknowledge
                  and agree that you have no, and will obtain no, rights in or
                  to any Listing and you represent and warrant that (i) you will
                  not exceed the term of any Reservation without the Listing
                  Host’s prior written consent; and (ii) you will not assert or
                  attempt to assert any ownership interest in any Listing
                  arising from or related to a Reservation.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>h) Insurance:</span> Host
                  does not provide any insurance protection or Guests or Listing
                  Hosts. You are solely responsible for obtaining insurance
                  coverage sufficient to protect your Listing, Reservation, or
                  other use of the App or any Listing or other property or asset
                  accessed in connection with your use of the App. You hereby
                  acknowledge and agree that you hold or will obtain and
                  maintain at all times during the term of these Terms the
                  appropriate insurance coverage sufficient to cover your
                  Listing, Rental, or other use of the App. You agree to provide
                  Host with copies of relevant proof of coverage upon request.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  7. Interactions With Other Users
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You assume all risks associated with dealing with other users
                  with whom you come in contact through the App, and, to the
                  extent that the law permits, you release us from any claims or
                  liability related to any of Your Content posted via the App
                  and from any claims related to the conduct of any other users.
                  Host reserves the right, but has no obligation, to intercede
                  in such disputes. You agree that Host will not be responsible
                  for any liability incurred as the result of such interactions.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Host is not a party to any transaction between Listing Hosts
                  and Guests, including any Reservation. You acknowledge and
                  agree that you, and not Host, will be responsible for
                  performing the obligations of any agreement you may have with
                  any other user (including without limitation any Reservation),
                  that Host is not a party to such agreement, and that, except
                  with respect to payment obligations expressly set forth
                  hereunder, Host disclaims all liability arising from or
                  related to any such agreements.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
                  INTERACTIONS WITH OTHER USERS OF THE APP, INCLUDING WITHOUT
                  LIMITATION GUESTS AND LISTING HOSTS. YOU UNDERSTAND THAT HOST
                  DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS,
                  WHETHER GUESTS OR LISTING HOSTS. HOST MAKES NO REPRESENTATIONS
                  OR WARRANTIES AS TO THE CONDUCT OF ANY USERS OF THE APP OR
                  THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS OF THE
                  APP. PLEASE TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS
                  AND INTERACTIONS WITH OTHER APP USERS, PARTICULARLY IF YOU
                  DECIDE TO MEET OFFLINE OR IN PERSON. YOU ACKNOWLEDGE AND AGREE
                  THAT HOST DOES NOT CONDUCT BACKGROUND CHECKS ON ANY USER OR
                  VERIFY THE ACCURACY OF ANY LISTING.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  8. Fees and Payments
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    a) Fees for Reservations:
                  </span>{" "}
                  You may download the App and create a profile for free. If you
                  use the App to make a Reservation as a Guest, you agree to pay
                  any and all fees or charges and applicable taxes associated
                  with your Reservation, including without limitation any fees
                  charged by Host in connection with such Reservation
                  (collectively, “Reservation Fees”). Pricing for Reservation
                  Fees are set by the applicable Listing Host with our fees
                  included. You agree that we are authorized to immediately
                  invoice your account for all Reservation Fees and charges due
                  and payable to Host hereunder, including any damage you cause
                  at or to a Listing, and that no additional notice,
                  authorization, or consent is required. You agree to pay all
                  fees or charges to your account in accordance with the
                  Reservation Fees, charges, and billing terms in effect at the
                  time a Reservation Fee or charge is due and payable.
                  Reservation Fees are non-cancellable and non-refundable unless
                  expressly permitted in these Terms or the applicable company
                  policy.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>b) Listing Fee:</span> If
                  you use the App as a Listing Host, you agree to pay the
                  applicable fee for each Reservation booked for your Listing
                  (the “Listing Fee”). Guests pay the Reservation Fee at the
                  time of booking. Host deducts the applicable Listing Fee from
                  the Reservation Fee (your “Listing Payment”) and transmits
                  your Listing Payment to your account on file within 72 hours
                  of the conclusion of the Reservation. All payment processing
                  is performed by Stripe or another PCI-DSS compliance
                  third-party payment processor.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    c) Payment Processing:
                  </span>{" "}
                  Host uses a third-party payment processor to process payments.
                  You authorize Host to charge your payment method for your
                  Reservation and any related fees or charges. You represent and
                  warrant that the payment information you provide to us is
                  correct and accurate and you are using a payment method that
                  you are legally authorized to use for this purpose. You agree
                  that you are solely liable for any payment or credit card
                  fraud, abuse, or unauthorized use by you or others. We reserve
                  the right at any time to change our prices and billing
                  methods.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>d) Promos:</span> From time
                  to time, Host may elect to offer promotions, deals, coupons,
                  or codes (each a “Promo”). A Promo must be used within the
                  specified time of the Promo. Promotional codes are generally
                  limited to one use per user. We may post additional terms
                  applicable to a Promo on the App or our website.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>e) Tax:</span> You agree to
                  make all payments of fees to Host free and clear of, and
                  without reduction for, any withholding taxes. Any such taxes
                  imposed on payments of fees to Host will be your sole
                  responsibility, and you will provide Host with official
                  receipts issued by the appropriate taxing authority, or such
                  other evidence as we may reasonably request, to establish that
                  such taxes have been paid. If any Reservation or payments in
                  connection with these Terms are subject to Tax in any
                  jurisdiction and you have not remitted the applicable Tax to
                  Host, you will be responsible for the payment of such Tax and
                  any related penalties or interest to the relevant authority,
                  and you will indemnify Host for any liability or expense we
                  may incur in connection with such Tax. Upon request, you are
                  required to provide Host with official receipts issued by the
                  appropriate taxing authority, or other such evidence that you
                  have paid all applicable taxes.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>f) Disputes:</span> If you
                  in good faith dispute any fees or charges to your host
                  account, you must notify us in writing within seven (7) days
                  after receiving the applicable statement to your credit card
                  or other payment method. If you do not provide written notice
                  of dispute within such window, any such dispute will be deemed
                  waived. Billing disputes should be notified to{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>
                  .
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  9. Your Content
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  The App includes features that allow you to post content in
                  the form of Listing content (descriptions, photos, maps,
                  etc.), comments, reviews, message boards, and direct messages
                  sent via the chat feature (collectively, “Your Content”). By
                  transmitting or submitting Your Content while using the App,
                  you affirm, represent and warrant that your Content is
                  accurate and not confidential, does not violate any applicable
                  laws, contractual restrictions or other third party rights,
                  and that you have permission from any third party whose
                  Personal Information is included in Your Content. You also
                  represent and warrant that Your Content is free of viruses,
                  adware, spyware, worms, or other malicious code. Host does not
                  control, endorse, or verify any of Your Content. You
                  understand that we may use Your Content to provide and improve
                  the App and for statistical, analytical, reporting and other
                  purposes (see our Privacy Policy).
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  In consideration for your use of the User Content features,
                  you grant to Host an irrevocable, worldwide, royalty-free,
                  fully paid, sub-licensable, perpetual license to use,
                  reproduce, make derivative works from, distribute, publish,
                  display, or perform Your Content in whole or in part, by any
                  and all means and media, in connection with advertising,
                  marketing and promoting the App, our products and the App. You
                  acknowledge that Host may modify Your Content for any purpose.
                  However, we have no obligation to use Your Content, and our
                  use of Your Content does not create or imply any endorsement
                  of or affiliation with you. Host reserves the right to remove
                  or modify any of Your Content that we deem objectionable in
                  our sole discretion.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You are solely responsible for Your Content and the
                  consequences of posting it online. We take no responsibility
                  and assume no liability for any of Your Content that you or a
                  third-party post or sends on or through the App, nor do we
                  assume any liability for any action or inaction regarding
                  transmissions, communications or content provided by any user
                  or third party.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  EXCEPT FOR OUR OBLIGATIONS TO PROTECT YOUR PERSONAL
                  INFORMATION AS SET FORTH IN OUR PRIVACY POLICY, HOST ASSUMES
                  NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY
                  OR FAILURE TO STORE ANY CONTENT (INCLUDING, BUT NOT LIMITED
                  TO, YOUR CONTENT), USER COMMUNICATIONS OR PERSONALIZATION
                  SETTINGS.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  10. Proprietary Rights
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Unless otherwise expressly indicated, the information
                  contained on the App, including but not limited to all images,
                  illustrations, designs, photographs, video clips, text,
                  graphics, icons, designs, software code, written information
                  and screens appearing in the App, and other materials, as well
                  as names, logos, taglines, trade dress, and other trademarks,
                  on the Site or in our other Services, are copyrights,
                  trademarks, trade dress or other intellectual property
                  (collectively, the “Contents”) owned, controlled, or licensed
                  by Host or its affiliates, or are the property of their
                  respective owners. Except as expressly provided herein, no
                  license to or regarding any of the Contents is granted in
                  connection with your use of the App. You understand that your
                  use of the App does not authorize you to use any Contents in
                  any manner other than specifically authorized by these Terms.
                  You may not use our Contents in any way that might confuse or
                  that disparages us. Any other use of the Contents in the App
                  including reproduction for purposes other than as noted
                  herein, without the prior written permission of Host, is
                  strictly prohibited.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>a) Copyright:</span> The
                  Contents are protected by U.S. and foreign copyright,
                  trademark, trade dress, or other proprietary right laws and
                  international conventions. You do not have permission to copy,
                  reproduce, make derivative works from, distribute, republish,
                  download, display, perform, post electronically or
                  mechanically, transmit, record, or mirror any of the Contents
                  without the prior written permission of Host. You may only
                  display, download, or print the Contents for the purpose of
                  using the App as an internal or personal business resource.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>b) Trademarks:</span> The
                  Host name, other product and service names, trademarks,
                  service marks, product names and trade names associated with
                  Host, are exclusively owned by Host and may not be copied,
                  imitated, or used, whether in whole, partial or modified form,
                  without the prior written permission of Host. You may not use
                  any meta tags or any other hidden text utilizing a Host name,
                  trademark, or product name without Host’s prior written
                  permission. Third party trademarks and service marks used in
                  the App, if any, are the property of their respective owners,
                  and we use them with their consent. Host and the other
                  licensors of the marks in the App reserve all rights with
                  respect to all Contents and all intellectual property.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>c) Feedback:</span> You may
                  from time-to-time provide us materials, communications,
                  suggestions, comments, improvements, ideas or other feedback
                  related to the Site or the App (“Feedback”). You hereby
                  additionally grant to us all rights, titles, and interests in
                  and to any Feedback. In the event this grant is not sufficient
                  for us to fully realize and use the Feedback, you grant us a
                  royalty-free, worldwide, transferable, sub-licensable,
                  irrevocable, perpetual license to use and incorporate into the
                  Site or the App any of the Feedback. By providing Feedback,
                  you are representing that the Feedback is not subject to any
                  intellectual property claim by a third party or any license
                  terms which would require products or services derived from
                  that Feedback to be licensed to or from, or shared with, any
                  third party.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>d) Reports:</span> We may,
                  from time to time, deidentify or anonymize the Personal
                  Information or other user data (“Deidentified Information”)
                  that we collect from your use of the App and the App and
                  combine it with others’ Deidentified Information for research,
                  analysis, machine learning, or to generate reports and studies
                  (collectively, “Reports”). Any such Reports are the sole and
                  exclusive property of Host, and we reserve the right to
                  disclose, share, distribute, or publish our Reports for any
                  purpose provided such Reports are not reasonably linkable,
                  directly or indirectly, to your Personal Information. You
                  hereby assign any rights you may have to such Reports,
                  studies, and your Deidentified Information contained therein
                  to Host in perpetuity throughout the world for any and all
                  present or future uses in any and all languages and for no
                  compensation. All Deidentified Information will be treated as
                  nonconfidential and nonproprietary as to the user. We shall be
                  under no obligation of any kind with respect to such
                  Deidentified Information and shall be free to reproduce, make
                  derivative works from, use, disclose, and distribute the
                  Reports to others without limitation. Additionally, we may use
                  any ideas, concepts, know-how, or techniques contained in
                  generating reports or studies for any purpose whatsoever,
                  including but not limited to developing, manufacturing, or
                  marketing products incorporating such information.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  11. Your Use of the App
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You represent and warrant that (a) you will use the App in
                  compliance with applicable laws; (b) any information you
                  submit to us is truthful and accurate; (c) you will maintain
                  the accuracy of that information; (d) you will not do anything
                  that might jeopardize the security of your account; and (e)
                  you will notify us immediately of any unauthorized access to
                  or use of your user name or password or any other breach of
                  security. Any information that you provide to us will also be
                  subject to our Privacy Notice.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You agree to not rent, retransmit, disclose, publish, sell,
                  assign, lease, sublicense, market or transfer the App or any
                  portion thereof (including our Content) or use it in any
                  manner not expressly authorized by these Terms. You agree not
                  to copy, reverse engineer, translate, port, modify, or make
                  derivative works of any portion of the App. Tampering with the
                  App, conducting fraudulent activities on the App and all other
                  illegal activities are prohibited and may subject a user to
                  legal action and/or termination of your access to the App.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You further agree not to (i) upload any Your Content (defined
                  above) or other data that contains software viruses or is
                  designed to interrupt, destroy, or limit the functionality of
                  any equipment or services, or that contains other harmful,
                  disruptive, or destructive files or content; (ii) use or
                  attempt to use another user’s account without authorization,
                  or impersonate any person or entity; (iii) harvest, solicit,
                  or collect information of other users for any reason
                  whatsoever, including, without limitation, for sending
                  unsolicited communications; (iv) post, advertise, or promote
                  products or services commercially, or upload any content that
                  is advertising, promotional material, junk mail, spam, or a
                  contest or sweepstake, or that furthers or promotes criminal
                  activity; or (v) use the App in any manner that, in our sole
                  discretion, is objectionable or restricts or inhibits any
                  other person from using or enjoying the App, or which may
                  expose us or our users to any harm or liability of any type.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You are strictly prohibited from violating or trying to
                  violate our security features, such as by (a) accessing data
                  not intended for you or logging onto a server or an account
                  which you are not authorized to access; (b) attempting to
                  probe, scan, or test the vulnerability of a system or network
                  or to breach security or authentication measures unless we
                  expressly authorize that you do so in writing; (c) attempting
                  to interfere with service to any user, host, or network, such
                  as by means of submitting a virus to the Site, overloading,
                  “flooding,” “spamming,” “mail bombing,” or “crashing”; or (d)
                  sending unsolicited email, including promotions and/or
                  advertising of products or services forging any TCP/IP packet
                  header or any part of the header information in any email or
                  newsgroup posting. You hereby agree not to use any device,
                  software, or routine to interfere, or try to interfere, with
                  the proper working of the App or any activity being conducted
                  on the App. You further agree not to use, or try to use, any
                  engine, software, tool, agent, or other device or mechanism
                  (including browsers, spiders, robots, avatars, or intelligent
                  agents) to navigate or search the App other than the search
                  engine and search agents that we make available via the App
                  and other than the generally available third-party web
                  browsers. If you violate our system or network security, you
                  may face civil or criminal liability. We will investigate
                  occurrences that may involve such violations. We may involve
                  or cooperate with law enforcement authorities in prosecuting
                  users who are involved in such violations.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  12. Software Requirements
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You must have a compatible mobile telephone or handheld
                  device, internet access, and the necessary minimum
                  specifications (“Software Requirements”) to use the App on a
                  mobile device. The Software Requirements are listed on the
                  relevant application page. App software may be upgraded from
                  time to time to add support for new functions. The App may
                  request certain privacy permissions from time to time such as
                  access to your geolocation or mapping applications, device
                  camera or microphone, or other apps and associated features on
                  your device. You acknowledge that the terms of agreement with
                  your respective mobile network provider will continue to apply
                  while you use the App. Data and messaging charges may apply to
                  your use of the App or any text messaging or photo sharing
                  features you use via the App. You accept responsibility for
                  any such charges that arise. If you are not the bill payer for
                  the mobile telephone or handheld device being used to access
                  the App, you will be assumed to have received permission from
                  the bill payer for use of the App.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  From time to time, we may, in our sole discretion, develop and
                  provide updates to the App, which may include upgrades, bug
                  fixes, patches, other error corrections, and/or new features
                  (collectively, including related documentation, “Updates”).
                  Updates may also modify or delete in their entirety certain
                  features and functionality. You agree that we have no
                  obligation to provide any Updates or to continue to provide or
                  enable any particular features or functionality. Based on your
                  device settings, when an internet-connected device is
                  connected to the internet, either (a) the App will
                  automatically download and install all available Updates; or
                  (b) you may receive notice of or be prompted to download and
                  install available Updates. You agree to promptly download and
                  install all Updates and acknowledge and agree that the App or
                  portions thereof may not properly operate should you fail to
                  do so. You further agree that all Updates will be deemed part
                  of the App and be subject to these Terms.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  13. Beta Versions
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  We may make versions of the App available for testing and
                  evaluation purposes prior to their release to the general
                  public (“Beta Versions”). Beta Versions are subject in all
                  respects to these Terms, except that we may discontinue the
                  App or your ability to use a Beta Version at any time, with or
                  without notice and without further obligations to you. By
                  participating in a Beta Version test, you consent to Host
                  accessing all data, including your Personal Information, input
                  or collected via your use of the App for Host to identify
                  bugs, discrepancies, errors, or improvements in the App. You
                  also understand and agree that we may contact you to learn
                  more about your use of the App or related activities to
                  improve the App. You agree to notify us of all comments or
                  suggestions about the App, including without limitation any
                  problems and ideas for improvements, which come to your
                  attention during use of the Beta Version. By permitting you to
                  access, download, install or use a Beta Version, we do not
                  grant any additional right to you under any copyrights,
                  patents, trademarks, or trade secret information. Host
                  reserves the right to condition your access to and use of a
                  Beta Version on your execution of a Nondisclosure Agreement.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  14. DMCA/Infringement Notification
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  We respect the intellectual property of others, and we ask our
                  users to do the same. If you believe in good faith that any
                  material on the App infringes a copyright in your work, a
                  notification of the alleged copyright infringement should be
                  emailed to our Copyright Agent at:{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>{" "}
                  (Subject Line: "DMCA Takedown Request"). To be effective, the
                  notification must be in writing and contain the following
                  information:
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of the copyrighted work that is
                  allegedly infringed;
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  Identification of the copyrighted work claimed to have been
                  infringed;
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  Identification of the material that is claimed to be
                  infringing, and information reasonably sufficient to permit us
                  to locate the material;
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  Information reasonably sufficient to permit us to contact you,
                  such as an address, telephone number, and, if available, an
                  electronic mail address;
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  A statement that you have a good faith belief that use of the
                  material in the manner complained of is not authorized by the
                  copyright owner, its agent, or the law; and
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are
                  authorized to act on behalf of the copyright owner.
                </span>
              </div>

              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Please be advised that we will not respond to complaints that
                  do not meet the requirements above. Notices must meet the
                  then-current statutory requirements imposed by the Digital
                  Millennium Copyright Act (DMCA). If we determine that the
                  material(s) alleged to infringe your rights do not require
                  removal, we will remove those materials only pursuant to a
                  court order that declares the content or use of the materials
                  unlawful. Please be aware that there can be penalties for
                  false claims under the DMCA. In accordance with the DMCA and
                  other applicable law, we have adopted a policy of terminating,
                  in appropriate circumstances and at our sole discretion, the
                  Account and access to the App by users who are deemed to be
                  repeat infringers. We may also at its sole discretion limit
                  access to the App and/or terminate the participation of any
                  users who infringe any intellectual property rights of others,
                  whether or not there is any repeat infringement.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  15. Termination
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You may terminate these Terms at any time by deleting your
                  Host account, deleting the App from your device, and
                  discontinuing all use of the App and other Host products and
                  services. Once you delete your account, data associated with
                  your account may no longer be available to you. If you
                  terminate these Terms, if applicable we will retain any fees
                  or other amounts you already paid to us under these Terms. We
                  may terminate your use of the App and these Terms immediately
                  upon giving notice to you if you breach any of these Terms or
                  any other agreement between you and us. In addition, we may
                  terminate your use of the App and these Terms at any time and
                  for any other reason. Cause for such termination includes, but
                  is not limited to, (a) breaches or violations of these Terms
                  or any agreement or policy incorporated herein; (b) requests
                  by law enforcement or other government agencies; (c) a request
                  by you (self-initiated account deletions); (d) discontinuance
                  or material modification to the App (or any portion thereof);
                  (e) unexpected technical or security issues or problems; (f)
                  extended periods of inactivity; and/or (g) nonpayment of any
                  fees owed by you in connection with the App. Termination of
                  your account may include (i) removal of access to all
                  offerings within the App; (ii) deletion of your data and User
                  Content; and (iii) barring of further use of the App. You
                  agree that all terminations for cause shall be made in Host’s
                  sole discretion and that Host shall not be liable to you or
                  any third party for any termination of your account or access
                  to the App. Sections 10 though 16 and 18 through 22 shall
                  survive termination of your account and/or the Terms.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Upon termination of these Terms (x) the rights and licenses
                  granted to you herein shall terminate as to the terminated
                  rights; (y) you shall cease all use of the App that have been
                  terminated; and (z) Host may at its own discretion remove
                  and/or purge data, Account information, and any other
                  information obtained by us in connection with providing you
                  the App. We reserve the right to disable any username,
                  password, or other identifier, whether chosen by you or
                  provided by us, at any time in our sole discretion for any or
                  no reason, including if, in our opinion, you have violated any
                  provision of these Terms.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  16. Disclaimer of Warranties
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  YOU USE THE APP AT YOUR OWN RISK. THE APP AND ALL COMPONENTS
                  THEREOF ARE PROVIDED TO YOU “AS IS,” WITHOUT WARRANTY OF ANY
                  KIND. WITHOUT LIMITING THE FOREGOING, HOST AND ITS
                  SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                  PARTNERS, AND LICENSORS EXPLICITLY DISCLAIM ALL EXPRESS OR
                  IMPLIED WARRANTIES ARISING OUT OF OR RELATED TO THESE TERMS OR
                  THE APP, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS
                  FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT,
                  AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE
                  OF TRADE. Without limiting the generality of the foregoing, we
                  make no warranty that the App or our Contents will meet your
                  requirements or be available on an uninterrupted, secure, or
                  error-free basis. We make no warranty regarding the quality,
                  accuracy, timeliness, truthfulness, completeness, or
                  reliability of the App or any Contents. YOU FURTHER
                  ACKNOWLEDGE AND AGREE THAT HOST IS NOT LIABLE, AND YOU AGREE
                  NOT TO SEEK TO HOLD HOST LIABLE, FOR THE CONDUCT OF THIRD
                  PARTIES, INCLUDING ANY OPERATORS OF EXTERNAL WEBSITES, AND
                  THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY
                  WITH YOU.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  17. Limitation of Liability
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  NEITHER HOST NOR ANY OTHER PARTY INVOLVED IN CREATING,
                  PRODUCING, OR DELIVERING THE APP WILL BE LIABLE FOR ANY
                  INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES,
                  INCLUDING PERSONAL INJURY, LOST PROFITS, LOSS OF DATA OR
                  GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, OR SYSTEM
                  FAILURE OR THE COST OF SUBSTITUTE SERVICES ARISING OUT OF OR
                  IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY
                  TO USE THE APP, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                  (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL
                  THEORY, AND WHETHER OR NOT HOST HAS BEEN INFORMED OF THE
                  POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
                  HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  TO THE FULLEST EXTENT PROVIDED BY LAW, HOST WILL NOT BE LIABLE
                  TO YOU FOR MORE THAN THE GREATER OF (A) THE TOTAL AMOUNT
                  RETAINED BY HOST FROM YOU IN THE TRANSACTION OR OTHER ACT OR
                  OMISSION GIVING RISE TO SUCH LIABILITY; (B) $100; OR (C) THE
                  REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH
                  CLAIM ARISES. THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET
                  FORTH HEREIN ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE
                  BARGAIN BETWEEN HOST AND YOU. SOME JURISDICTIONS DO NOT ALLOW
                  THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                  INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO
                  YOU. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
                  FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN HOST
                  AND YOU.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  18. Indemnification
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  YOU AGREE TO INDEMNIFY AND HOLD HOST AND ITS SUBSIDIARIES,
                  AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES, EMPLOYEES,
                  PARTNERS AND LICENSORS HARMLESS FROM ANY CLAIMS, SUITS,
                  ACTIONS, DEMANDS, DISPUTES, ALLEGATIONS, OR INVESTIGATIONS
                  BROUGHT BY ANY THIRD PARTY, GOVERNMENTAL AUTHORITY, OR
                  INDUSTRY BODY, AND ALL LIABILITIES, DAMAGES, LOSSES, COSTS,
                  AND EXPENSES, INCLUDING WITHOUT ARISING OUT OF YOUR CONTENT,
                  YOUR USE OF (OR INABILITY TO USE) THE APP, YOUR VIOLATION OF
                  THESE TERMS, YOUR VIOLATION OF APPLICABLE LAW OR LISTING
                  RULES, YOUR INTERACTION WITH ANY USER OR LISTING, OR THE USE,
                  CONDITION OR RENTAL OF A LISTING BY YOU. THE FOREGOING
                  INCLUDES, WITHOUT LIMITATION, ANY INJURIES, LOSSES, OR DAMAGES
                  (COMPENSATORY, DIRECT, INCIDENTAL, CONSEQUENTIAL OR OTHERWISE)
                  OF ANY KIND ARISING IN CONNECTION WITH OR AS A RESULT OF A
                  RENTAL, RESERVATION OR USE OF A LISTING. HOST RESERVES THE
                  RIGHT, AT ITS OWN COST, TO ASSUME THE EXCLUSIVE DEFENSE AND
                  CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY
                  YOU, IN WHICH EVENT YOU WILL FULLY COOPERATE WITH HOST IN
                  ASSERTING ANY AVAILABLE DEFENSES. YOU AGREE THAT THE
                  PROVISIONS IN THIS SECTION WILL SURVIVE ANY TERMINATION OF
                  THESE TERMS.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  19. Damages Claims
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Notwithstanding Section 20, if your use of a Listing in
                  connection with a Reservation results in any damage to such
                  Listing, you will compensate the Listing Host of such Listing
                  for any such damage. In the event that a Listing Host alleges
                  that a Guest’s use of such their Listing in connection with a
                  Reservation results in any damage to such Listing (each, a
                  “Damages Claim”), the Guest will be notified of such Damages
                  Claim. After being notified of the Damages Claim and given
                  forty-eight (48) hours to respond to such Damages Claim in
                  accordance with the methods set forth in the notice, payment
                  in the amount alleged by the Listing Host in the Damages Claim
                  will be charged to and taken from the payment method on file
                  in the Guest’s Host account. You agree that we are authorized
                  to immediately charge your account for all fees and charges
                  due and payable hereunder and that no additional notice or
                  consent is required. IF WE ARE UNABLE TO CHARGE THE PAYMENT
                  METHOD ON FILE OR OTHERWISE COLLECT PAYMENT FROM YOU IN
                  CONNECTION WITH A DAMAGES CLAIM, YOU AGREE TO REMIT PAYMENT IN
                  THE AMOUNT ALLEGED IN THE DAMAGES CLAIM TO THE APPLICABLE
                  LISTING HOST OR TO HOST, AS APPLICABLE. YOU AGREE TO COOPERATE
                  WITH AND ASSIST HOST IN GOOD FAITH, AND TO PROVIDE US WITH
                  SUCH INFORMATION AND TAKE SUCH ACTIONS AS MAY BE REASONABLY
                  REQUESTED BY US, IN CONNECTION WITH ANY DAMAGES CLAIM. Upon
                  Host’s reasonable request and at no cost to you, you agree to
                  participate in mediation or similar resolution process in
                  connection with any disputed Damages Claim, which process will
                  be conducted by _______
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  20. Dispute Resolution
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT
                  CONTAINS A CLASS ACTION WAIVER, REQUIRES YOU TO ARBITRATE
                  CERTAIN DISPUTES, AND CLAIMS AND LIMITS THE MANNER IN WHICH
                  YOU CAN SEEK RELIEF FROM US. Except as provided in Section 19,
                  you agree that any dispute, controversy or claim between you
                  and Host arising out of or relating to: (1) these Terms, or
                  the breach thereof; (2) our provision of the App to you; (3)
                  your access to or use of the App; (4) your Listing,
                  Reservation, or use thereof or any interaction with other
                  users related to the App; or (5) any alleged violation of any
                  federal, state, or local law, statute, or ordinance (each such
                  dispute, controversy or claim, a “Dispute”) will be governed
                  by the arbitration procedure outlined below.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    a) Informal Dispute Resolution:
                  </span>{" "}
                  We want to address your concerns without the necessity of a
                  formal legal case. Before filing a claim against Host, you
                  agree to try to resolve the Dispute informally by contacting{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>
                  . Host will contact you by email as part of a good faith
                  effort to resolve the Dispute informally. If a Dispute is not
                  resolved within 30 days after submission, you or Host may
                  bring a formal proceeding.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    b) Arbitration Agreement:
                  </span>{" "}
                  You and Host each agree to resolve any Disputes through final
                  and binding arbitration administered by JAMS Mediation,
                  Arbitration and ADR Services. The arbitration will be held in
                  Othello, Washington, United States, or any other location we
                  agree to. The JAMS rules will govern payment of all
                  arbitration fees. Either party may bring a lawsuit solely for
                  injunctive relief to stop unauthorized use or abuse of the
                  App, or infringement of intellectual property rights (for
                  example, trademark, trade secret, copyright, or patent rights)
                  without first engaging in arbitration or the informal dispute
                  resolution process described above.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    c) Class Action Waiver:
                  </span>{" "}
                  You may only resolve Disputes with Host on an individual basis
                  and may not bring a claim as a plaintiff or a class member in
                  a class, consolidated, or representative action. Class
                  arbitrations, class actions, private attorney general actions,
                  and consolidation with other arbitrations are not allowed
                  under these Terms.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>d) Governing Law:</span>{" "}
                  Except as otherwise required by applicable law, the Terms and
                  the resolution of any Disputes shall be governed by and
                  construed in accordance with the laws of the State of
                  Washington without regard to its conflict of laws principles.
                  The Federal Arbitration Act, Washington state law, and
                  applicable U.S. federal law, without regard to the choice or
                  conflicts of law provisions, will govern these Terms. Except
                  as otherwise required by applicable law, if the agreement to
                  arbitrate is found not to apply to you or your claim, you and
                  Host agree that any judicial proceeding (other than small
                  claims actions) will be brought in the federal or state courts
                  in Othello, Washington.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    e) Limitation on Claims:
                  </span>{" "}
                  Regardless of any statute or law to the contrary, any claim or
                  cause of action arising out of or related to your use of the
                  App must be filed within one (1) year after such claim or
                  cause of action arose, or else that claim or cause of action
                  will be barred forever.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  21. General Terms
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    a) Geographic Restrictions:
                  </span>{" "}
                  Host is owned and operated in the United States. We make no
                  claims that the App or any other products or services or their
                  content is accessible or appropriate outside of the United
                  States. If you access the App from outside the United States,
                  you do so on your own initiative and are responsible for
                  compliance with local laws. Access to the App, or certain
                  third-party services available through the App, may not be
                  legal by certain persons or in certain countries.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>b) Entire Agreement:</span>{" "}
                  Except as otherwise stated herein, these Terms constitute the
                  entire and exclusive understanding and agreement between Host
                  and you regarding the App, and these Terms supersede and
                  replace any and all prior oral or written understandings or
                  agreements between Host and you regarding the App and our
                  Contents.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    c) Relationship of Parties:
                  </span>{" "}
                  The parties’ relationship, as established by these Terms, is
                  solely that of independent contractors. These Terms do not
                  create any partnership, joint venture, or similar business
                  relationship between the parties. Neither party is a legal
                  representative of the other party, and neither party can
                  direct and control the day-to-day activities of the other,
                  assume or create any obligation, representation, warranty, or
                  guarantee, express or implied, on behalf of the other party
                  for any purpose whatsoever.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>d) Assignment:</span> You
                  may not assign, delegate, or transfer these Terms, by
                  operation of law or otherwise, without our prior written
                  consent. Any attempt by you to assign or transfer these Terms,
                  without such consent, will be null. Host may freely assign or
                  transfer these Terms without restriction. Subject to the
                  foregoing, these Terms will bind and inure to the benefit of
                  the parties, their successors, and permitted assigns.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>e) Enforcement:</span> Host
                  reserves the right (but is not required) to remove or disable
                  your access to the App, any Content, or Your Content at any
                  time and without notice, and at our sole discretion, if we
                  determine that your use of the App or your User Content is
                  objectionable or in violation of these Terms. We have the
                  right to investigate violations of these Terms and any conduct
                  that affects the App, and in response may take any action we
                  may deem appropriate. If you breach any of the terms or
                  conditions of this Terms and Conditions of Use policy, you
                  hereby agree to pay all reasonable attorneys’ fees incurred by
                  Host in enforcing the terms and conditions of this Terms and
                  Conditions of Use policy. The attorneys’ fees shall be paid by
                  you irrespective of any damages recovered or relief afforded
                  to Host.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    f) Waiver and Severability:
                  </span>{" "}
                  Host’s failure to enforce any right or provision of these
                  Terms will not be considered a waiver of such right or
                  provision. The waiver of any such right or provision will be
                  effective only if in writing and signed by a duly authorized
                  representative of Host. Except as expressly set forth in these
                  Terms, the exercise by either party of any of its remedies
                  under these Terms will be without prejudice to its other
                  remedies under these Terms or otherwise. If for any reason a
                  court of competent jurisdiction finds any provision of these
                  Terms invalid or unenforceable, that provision will be
                  enforced to the maximum extent permissible, and the other
                  provisions of these Terms will remain in full force and
                  effect.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    g) Consent to Electronic Communications:
                  </span>{" "}
                  By downloading or using the App, creating a profile, or typing
                  your name into any of our electronic forms and indicating your
                  acceptance or submission of information by clicking a box, you
                  consent to (a) our communicating with you electronically; (b)
                  receiving all applications, notices, disclosures, and
                  authorizations (collectively, “Records”) from us
                  electronically; and (c) entering into agreements and
                  transactions using electronic Records and signatures. Please
                  note that federal law treats electronic signatures as having
                  the same legal force and effect as if they were signed on
                  paper by hand, and online contracts have the same legal force
                  as signing an equivalent paper contract in ink. We will use
                  electronic documents for all communications, notices,
                  agreements, disclosures, authorizations, and other documents
                  necessary to provide you with the App. You agree that any
                  notices, agreements, disclosures, or other communications that
                  we send to you electronically will satisfy any legal
                  communication requirements, including that such communications
                  be in writing. You must have a computer or other web-enabled
                  device, an internet connection, an active email account, and
                  the ability to receive and read PDF files to conduct business
                  with us electronically. You agree to be responsible for
                  keeping your own Records. If you require assistance with your
                  Records or if you wish to receive Records in paper format or
                  to withdraw your consent to receiving electronic records from
                  us, please contact us at{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>
                  . Agreements and transactions executed prior to this request
                  will remain valid and enforceable.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>h) Notices:</span> Host
                  reserves the right to update the Terms at any time and for any
                  reason in its sole discretion. We will notify you of any
                  material changes to the Terms or to any service or other
                  features of the App. Any notices or other communications
                  provided by us under these Terms, including those regarding
                  modifications to these Terms, will be given (i) via email; or
                  (ii) by posting to particular pages of the App. For notices
                  made by email, the date of receipt on the message will be
                  deemed the date on which such notice is transmitted.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>i) Amendments:</span> Host
                  reserves the right to amend these Terms of Use at any time by
                  updating this posting without prior notice. Your continued use
                  of the App following the posting of an updated Terms of Use
                  constitutes your acceptance of such amendments. If the updated
                  Terms are not acceptable to you, your only recourse is to
                  cease using the App. You are advised to periodically visit
                  this page to determine the then current Terms of Use.
                </span>
              </div>
            </div>
          </Stack>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
