import { useState } from "react";
import { Row, Col, Container, Stack, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoOrange from "../../assets/host_logo_negative_orange.png";
import logoBlack from "../../assets/host_logo_negative_black.png";
import styles from "./HomePage.module.css";
import background from "../../assets/fall_background.png";

interface ButtonStates {
  hostListing: boolean;
  search: boolean;
  searchMode: boolean;
  profile: boolean;
  logo: boolean;
}

export function Home() {
  const [buttonState, setButtonState] = useState<ButtonStates>({
    hostListing: false,
    search: false,
    searchMode: false,
    profile: false,
    logo: false,
  });

  return (
    <div
      className="d-flex flex-column vh-100"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* Header */}
      <Row
        xs={3}
        className={`align-items-center mb-4 ${styles.header}`}
      >
        <Col xs={6}>
          <Row>
            <div className="d-flex align-items-center">
              <div
                className={`m-2 ${buttonState.logo ? "hovered" : ""}`}
                onMouseEnter={() =>
                  setButtonState({ ...buttonState, logo: true })
                }
                onMouseLeave={() =>
                  setButtonState({ ...buttonState, logo: false })
                }
              >
                <img
                  src={buttonState.logo ? logoBlack : logoOrange}
                  alt="H"
                  className={`img-fluid ${styles.logo}`}
                />
              </div>

              {["H", "O", "S", "T"].map((letter, index) => (
                <div key={index} className="m-1">
                  <span className={`${styles.logoLetter}`}>{letter}</span>
                </div>
              ))}
            </div>
          </Row>
        </Col>

        <Col xs={6}>
          <div className="d-flex justify-content-end">
            <Link to="/about">
              <span className={`${styles.headerLink}`}>About</span>
            </Link>
            <Link to="/signin">
              <span className={`${styles.headerLink}`}>Sign In</span>
            </Link>
          </div>
        </Col>
      </Row>

      {/* Body */}
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        <Container fluid>
          <Col xs={6} className="justify-content-center align-items-center">
            <Stack gap={2} direction="vertical" className={`${styles.hero}`}>
              <>
                <span className={`${styles.mainHero}`}>
                  Let us <span className={`${styles.gradientText}`}>HOST</span>{" "}
                  you!
                </span>
                <span className={`${styles.subHero}`}>
                  Whether you prefer tent camping, RV sites, or need space for
                  livestock boarding, we've got your next adventure covered.
                </span>
              </>
              <Link to="/signup">
                <Button className={`${styles.button}`}>Join for Free</Button>
              </Link>
            </Stack>
          </Col>
        </Container>
      </div>

      {/* Footer */}
      <Row className={`align-items-center ${styles.footer}`} />
    </div>
  );
}
