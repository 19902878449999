import { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FaHorse, FaCampground, FaSearch } from "react-icons/fa";
import logoOrange from "../../assets/host_logo_negative_orange.png";
import logoBlack from "../../assets/host_logo_negative_black.png";
import colors from "../../theme/colors";
import {
  GetUserQuery,
  GetUserQueryVariables,
  ListListingsQuery,
  ListListingsQueryVariables,
} from "../../API";
import { useQuery } from "@apollo/client";
import { useAuthContext } from "../../contexts/AuthContext";
import { getUser, listListings } from "./queries";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import UserImage from "../../components/UserImage/UserImage";
import { Footer } from "../../components/Footer/Footer";
import { Loader } from "../../components/Loader/Loader";
import ListingCard from "../../components/ListingCard/ListingCard";
import { ICoordinate } from "../../types/models";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays } from "date-fns";
import styles from "./SearchPage.module.css";

interface ButtonStates {
  hostListing: boolean;
  search: boolean;
  searchMode: string;
  profile: boolean;
  logo: boolean;
}

export function Search() {
  const navigate = useNavigate();
  const { userID } = useAuthContext();
  const autocompleteRef = useRef(null);
  const [buttonState, setButtonState] = useState<ButtonStates>({
    hostListing: false,
    search: false,
    searchMode: "CAMP",
    profile: false,
    logo: false,
  });
  const [usecase, setUsecase] = useState<string>("");
  const [mapView, setMapView] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [where, setWhere] = useState<null | string>(null);
  const [lat, setLat] = useState<null | number>(null);
  const [lng, setLng] = useState<null | number>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dates, setDates] = useState<string[]>([]);

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const generateDates = () => {
    const array: string[] = [];
    let currentDate = startDate;

    while (currentDate && endDate && currentDate <= endDate) {
      array.push(format(currentDate, "yyyy-MM-dd"));
      currentDate = addDays(currentDate, 1);
    }

    return array;
  };

  useEffect(() => {
    if (startDate && endDate) {
      setDates(generateDates());
    } else {
      setDates([]);
    }
  }, [startDate, endDate]);

  async function initMap(autocompleteRef: any) {
    // Request needed libraries.
    //@ts-ignore
    const [{ Autocomplete }] = await Promise.all([
      google.maps.importLibrary("places"),
    ]);

    // Create the input HTML element, and append it.
    const input = autocompleteRef.current;
    if (input) {
      const autocomplete = new Autocomplete(input, {
        types: ["(cities)"], // Restrict to cities
      });

      // Add a place_changed event listener.
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          const placeName = place.name;
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          setWhere(placeName);
          setLat(latitude);
          setLng(longitude);
        }
      });
    }
  }

  useEffect(() => {
    initMap(autocompleteRef);
  }, []);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery<GetUserQuery, GetUserQueryVariables>(getUser, {
    variables: { id: userID },
    fetchPolicy: "cache-and-network",
  });
  const user = userData?.getUser;

  const { data, loading, error, refetch } = useQuery<
    ListListingsQuery,
    ListListingsQueryVariables
  >(listListings);

  const listings =
    data?.listListings?.items?.filter(
      (listing) => listing?.active && !listing?._deleted
    ) ?? [];

  useEffect(() => {
    if (listings) {
      const newResults = listings.filter(
        (listing) => listing?.listingType == buttonState.searchMode
      );
      setSearchResults(newResults);
    }
  }, [buttonState.searchMode, data]);

  const distance = (coor1: ICoordinate, coor2: ICoordinate) => {
    const x = coor2.latitude - coor1.latitude;
    const y = coor2.longitude - coor1.longitude;
    return Math.sqrt(x * x + y * y);
  };

  const sortByDistance = (listings: any[], coor: ICoordinate) => {
    const sorter = (a: ICoordinate, b: ICoordinate) =>
      distance(a, coor) - distance(b, coor);
    return listings.sort(sorter);
  };

  const makeSearch = () => {
    const newResults = listings
      ? sortByDistance(
          listings.filter(
            (listing: any) =>
              listing?.active &&
              !listing?._deleted &&
              listing?.listingType == buttonState.searchMode &&
              !listing?.Calendar?.unavailableDates.some((item: any) =>
                dates.slice(0, dates.length - 1).includes(String(item))
              ) &&
              !listing?.bookedDates.some((item: any) =>
                dates.slice(0, dates.length - 1).includes(String(item))
              )
          ),
          {
            latitude: lat ?? 0,
            longitude: lng ?? 0,
          }
        )
      : [];
    setSearchResults(newResults);
  };

  if (loading) {
    return <Loader />;
  }

  // if (error) {
  //   alert(`Bad internet connection, please refresh page`);
  // }

  return (
    <div className="d-flex flex-column vh-100 vw-100">
      {/* Header */}
      <div className={`${styles.header}`}>
        <div className={`${styles.headingOuter}`}>
          <div className="d-flex align-items-center">
            <div
              className={`m-2 ${buttonState.logo ? "hovered" : ""}`}
              onMouseEnter={() =>
                setButtonState({ ...buttonState, logo: true })
              }
              onMouseLeave={() =>
                setButtonState({ ...buttonState, logo: false })
              }
            >
              <img
                onClick={() => window.history.back()}
                src={buttonState.logo ? logoBlack : logoOrange}
                alt="H"
                className={`img-fluid ${styles.logo} ${styles.clickable}`}
              />
            </div>

            {["H", "O", "S", "T"].map((letter, index) => (
              <div
                key={index}
                onClick={() => window.history.back()}
                className="m-1"
              >
                <span className={`${styles.logoLetter} ${styles.clickable}`}>
                  {letter}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex flex-direction-row">
          <div
            className={`${styles.clickable} ${styles.modeContainer}`}
            onClick={() =>
              setButtonState({ ...buttonState, searchMode: "CAMP" })
            }
          >
            <FaCampground
              color={
                buttonState.searchMode == "CAMP"
                  ? colors.primary
                  : colors.medium
              }
              size={20}
            />
            <span
              className={`${styles.searchMode} ${
                buttonState.searchMode == "CAMP"
                  ? styles.primary
                  : styles.medium
              }`}
            >
              CAMP
            </span>
          </div>
          <div
            className={`${styles.clickable} ${styles.modeContainer}`}
            onClick={() =>
              setButtonState({ ...buttonState, searchMode: "RODEO" })
            }
          >
            <FaHorse
              color={
                buttonState.searchMode == "RODEO"
                  ? colors.primary
                  : colors.medium
              }
              size={20}
            />
            <span
              className={`${styles.searchMode} ${
                buttonState.searchMode == "RODEO"
                  ? styles.primary
                  : styles.medium
              }`}
            >
              RODEO
            </span>
          </div>
        </div>

        <div className={`${styles.headingOuter}`}>
          <div className={`${styles.profileContainer}`}>
            <div className={`${styles.signout} ${styles.clickable}`}>
              <span
                className={`${styles.signoutText}`}
                onClick={() => {
                  Auth.signOut();
                  navigate("/");
                }}
              >
                Sign Out
              </span>
            </div>
            <div
              className={`${styles.profile} ${styles.clickable}`}
              onClick={() =>
                alert("Profile management feature is currently in development!")
              }
            >
              <UserImage
                imageKey={user?.image}
                style={{
                  height: "40px",
                  width: "40px",
                  flex: 1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`mb-4 ${styles.searchHeader}`}>
        <div className={`${styles.searchBar}`}>
          <div className={`${styles.searchBarInner}`}>
            {/* Where */}
            <div
              className={`${styles.searchTitleContainer} ${styles.clickable}`}
              // onClick={() => setOpenSelector("where")}
            >
              <div className={`${styles.searchInputContainer}`}>
                <span className={`${styles.searchInputTitle}`}>Where</span>
                <div>
                  <input
                    ref={autocompleteRef}
                    placeholder="Select Destination"
                    style={{ border: "none" }}
                  />
                </div>
              </div>
            </div>
            {/* Check In */}
            <div
              className={`${styles.searchTitleContainer} ${styles.clickable}`}
              // onClick={() => setOpenSelector("startDate")}
            >
              <div className={`${styles.searchInputContainer}`}>
                <span className={`${styles.searchInputTitle}`}>Check in</span>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                />
              </div>
            </div>
            {/* Check Out */}
            <div
              className={`${styles.searchTitleContainer} ${styles.clickable}`}
              // onClick={() => setOpenSelector("endDate")}
            >
              <div className={`${styles.searchInputContainer}`}>
                <span className={`${styles.searchInputTitle}`}>Check out</span>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End Date"
                />
              </div>
            </div>
            {/* Make Search */}
            <div
              className={`${styles.searchLogo}`}
              onClick={() => makeSearch()}
            >
              <FaSearch color={colors.white} size={"2vh"} />
            </div>
          </div>
        </div>
      </div>
      {/* Body */}
      <Container
        style={{
          width: "100vw",
          height: "84vh",
          overflowY: "auto",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Row xs={1} sm={2} lg={3} xl={4} className="g-4">
          {searchResults.map((listing) => (
            <Col key={listing?.id}>
              <ListingCard
                basePrice={listing?.basePrice || 0}
                city={listing?.city || ""}
                state={listing?.state || ""}
                country={listing?.country || ""}
                dates={dates}
                images={listing?.images || [""]}
                listingID={listing?.id || ""}
                listingType={listing?.listingType || ""}
                monthlyDiscount={listing?.monthlyDiscount || 0}
                surgePrice={listing?.surgePrice || 0}
                surgePriceDates={listing?.Calendar?.surgePriceDates || []}
                title={listing?.title || ""}
                usecase={usecase || ""}
                weeklyDiscount={listing?.weeklyDiscount || 0}
              />
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
