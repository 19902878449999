import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stack, Button, Form } from "react-bootstrap";
import styles from "./SignUpPage.module.css";
import { FormInput } from "../components/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const USERNAME_REGEX = /^[a-zA-Z0-9_]*$/; // alphanumeric and underscore

type SignUpData = {
  name: string;
  email: string;
  username: string;
  password: string;
  passwordRepeat: string;
};

export const SignUp = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, watch } = useForm<SignUpData>();
  const pwd = watch("password");
  const [loading, setLoading] = useState<boolean>(false);

  const isPasswordValid = (password: string): boolean => {
    if (password.length < 8) {
      return false;
    }
    if (!/[a-z]/.test(password)) {
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      return false;
    }
    if (!/[0-9]/.test(password)) {
      return false;
    }
    if (!/[^a-zA-Z0-9]/.test(password)) {
      return false;
    }
    return true;
  };

  const onRegisterPressed = async ({
    name,
    email,
    username,
    password,
  }: SignUpData) => {
    if (loading) {
      return;
    }
    if (!isPasswordValid(password)) {
      alert("Password does not meet policy requirements.");
      return;
    }
    setLoading(true);
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          name,
          email,
        },
      });
      navigate(`/confirmemail/${username}`);
      alert(`An account has been created for ${username}`);
    } catch (e) {
      alert(`Ooops, ${(e as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack className={`${styles.authContainer} mx-auto`} direction="vertical">
      <span className={`${styles.title}`}>Create an Account</span>

      <Form onSubmit={handleSubmit(onRegisterPressed)}>
        <FormInput
          name="name"
          control={control}
          placeholder="Full name"
          rules={{
            required: "Name is required",
            minLength: {
              value: 3,
              message: "Name should be at least 3 characters long",
            },
            maxLength: {
              value: 24,
              message: "Name should be max 24 characters long",
            },
          }}
        />

        <FormInput
          name="username"
          control={control}
          placeholder="Username"
          rules={{
            required: "Username is required",
            minLength: {
              value: 3,
              message: "Username should be at least 3 characters long",
            },
            maxLength: {
              value: 24,
              message: "Username should be max 24 characters long",
            },
            pattern: {
              value: USERNAME_REGEX,
              message: "Username can only contain a-z, 0-9, _",
            },
          }}
        />
        <FormInput
          name="email"
          control={control}
          placeholder="Email"
          rules={{
            required: "Email is required",
            pattern: { value: EMAIL_REGEX, message: "Email is invalid" },
          }}
        />
        <FormInput
          name="password"
          control={control}
          placeholder="Password"
          secureTextEntry
          rules={{
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password should be at least 8 characters long",
            },
          }}
        />
        <FormInput
          name="passwordRepeat"
          control={control}
          placeholder="Repeat Password"
          secureTextEntry
          rules={{
            validate: (value: string) =>
              value === pwd || "Password do not match",
          }}
        />

        <Button className={`${styles.button}`} type="submit">
          {loading ? "Loading..." : "Register"}
        </Button>
      </Form>

      <span className={`${styles.text}`}>
        By registering, you confirm that you accept our{" "}
        <Link to="/terms">
          <span className={`${styles.link}`}>Terms of Service</span>
        </Link>
        {" and "}
        <Link to="/privacy">
          <span className={`${styles.link}`}>Privacy Policy</span>
        </Link>
      </span>

      <div className={`${styles.buttonContainer}`}>
        <Link to="/signin">
          <span className={`${styles.buttonText}`}>
            Have an account? Sign in
          </span>
        </Link>
      </div>
    </Stack>
  );
};
