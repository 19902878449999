import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stack, Button, Form } from "react-bootstrap";
import styles from "./ForgotPassword.module.css";
import { FormInput } from "../components/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

type ForgotPasswordData = {
  username: string;
};

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<ForgotPasswordData>();
  const [loading, setLoading] = useState(false);

  const onSendPressed = async ({ username }: ForgotPasswordData) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await Auth.forgotPassword(username);
      alert(
        `Check your email, the code has been sent to ${response.CodeDeliveryDetails.Destination}
      `
      );
      navigate("/newpassword");
    } catch (e) {
      alert(`Ooops, ${(e as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack className={`${styles.authContainer} mx-auto`} direction="vertical">
      <span className={`${styles.title}`}>Reset your Password</span>

      <Form onSubmit={handleSubmit(onSendPressed)}>
        <FormInput
          name="username"
          control={control}
          placeholder="Username"
          rules={{
            required: "Username is required",
          }}
        />

        <Button className={`${styles.button}`} type="submit">
          {loading ? "Loading..." : "Send"}
        </Button>
      </Form>

      <div className={`${styles.buttonContainer}`}>
        <Link to="/signin">
          <span className={`${styles.buttonText}`}>Back to Sign In</span>
        </Link>
      </div>
    </Stack>
  );
};
