import styles from "./FormInput.module.css";
import { Control, Controller, Path } from "react-hook-form";
import { Form } from "react-bootstrap";

interface ICustomInput<ContentType> {
  control: Control<any, object>;
  name: Path<ContentType>;
  rules?: any;
  placeholder?: string;
  secureTextEntry?: boolean;
}

export function FormInput<ContentType>({
  control,
  name,
  rules = {},
  placeholder = "",
  secureTextEntry = false,
}: ICustomInput<ContentType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <>
          <Form.Group>
            <Form.Control
              type={secureTextEntry ? "password" : "text"}
              defaultValue={value || ""}
              onChange={(e) => onChange(e.target.value)}
              onBlur={onBlur}
              placeholder={placeholder}
              isInvalid={error ? true : false}
              className={styles.container}
            />
            {secureTextEntry &&
              (placeholder === "Password" ||
                placeholder === "Enter your new password") && (
                <Form.Text className="text-muted">
                  Password must:
                  <ul>
                    <li>Be at least 8 characters in length</li>
                    <li>Contain at least 1 lowercase letter (a-z)</li>
                    <li>Contain at least 1 uppercase letter (A-Z)</li>
                    <li>Contain at least 1 numeric character (0-9)</li>
                    <li>Contain at least 1 symbol character</li>
                  </ul>
                </Form.Text>
              )}
            <Form.Control.Feedback type="invalid">
              {error && (error.message || "Error")}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
    />
  );
}
