import { gql } from "@apollo/client";

export const listListings = gql`
  query ListListings(
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostID
        step
        totalSteps
        listingType
        campType
        rodeoType
        address
        city
        state
        country
        zip
        latitude
        longitude
        automobile
        bigRig
        fifthWheel
        motorhome
        motorhomeTowing
        pickupCamper
        tent
        tentTrailer
        travelTrailer
        van
        otherCamp
        bovine
        caprine
        equine
        swine
        anyAnimals
        maxGuests
        maxLength
        maxWidth
        maxAnimals
        barn
        pasture
        pen
        shelter
        campPower
        campWater
        sewage
        firewood
        wifi
        cableTV
        rodeoPower
        rodeoWater
        feed
        stalls
        bedding
        specialServices
        surveillance
        smoking
        campfires
        pets
        party
        curfew
        additionalRules
        requiredEmail
        requiredMessage
        title
        description
        images
        basePrice
        surgePrice
        weeklyDiscount
        monthlyDiscount
        cancellation
        bookingInAdvance
        checkInTimeLower
        checkInTimeUpper
        checkOutTime
        Calendar {
          id
          name
          publisherID
          unavailableDates
          surgePriceDates
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookedDates
        nofReservations
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getUser = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstName
      lastName
      email
      emailVerified
      image
      nofListings
      nofStays
      nofNights
      verified
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;