import UserImage from "../../components/UserImage/UserImage";
import { useState, useEffect, useRef } from "react";
import logoOrange from "../../assets/host_logo_negative_orange.png";
import logoBlack from "../../assets/host_logo_negative_black.png";
import { GrLocation } from "react-icons/gr";
import { FaHorse, FaCampground, FaRegClock, FaSearch } from "react-icons/fa";
import {
  MdOutlineVerified,
  MdBookmarkBorder,
  MdBookmarkAdded,
} from "react-icons/md";
import { BsCalendar3Week, BsCalendar3 } from "react-icons/bs";
import { MarkerF, GoogleMap, LoadScript } from "@react-google-maps/api";
import colors from "../../theme/colors";
import styles from "./ListingDetails.module.css";
import { ICoordinate } from "../../types/models";
import {
  CreateSaveMutation,
  CreateSaveMutationVariables,
  DeleteSaveMutation,
  DeleteSaveMutationVariables,
  GetListingQuery,
  GetListingQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  Listing,
  SavesByListingQuery,
  SavesByListingQueryVariables,
} from "../../API";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthContext } from "../../contexts/AuthContext";
import {
  getListing,
  createSave,
  deleteSave,
  savesByListing,
  getUser,
} from "./queries";
import { Content } from "../../components/Content/Content";
import { Accommodation } from "../../components/Accomodation/Accommodation";
import { Footer } from "../../components/Footer/Footer";
import { Loader } from "../../components/Loader/Loader";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays } from "date-fns";

interface ButtonStates {
  logo: boolean;
}

const imageStyle: React.CSSProperties = {
  width: "100%",
  minHeight: "100%",
  position: "absolute",
  top: "0",
  left: "0",
};

export function ListingDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userID } = useAuthContext();

  // const {
  //   dates,
  //   usecase,
  // } = route.params;
  const usecase = "";

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery<GetUserQuery, GetUserQueryVariables>(getUser, {
    variables: { id: userID },
    fetchPolicy: "cache-and-network",
  });
  const user = userData?.getUser;

  const { data, loading, error, refetch } = useQuery<
    GetListingQuery,
    GetListingQueryVariables
  >(getListing, {
    variables: { id: id || "" },
    fetchPolicy: "cache-and-network",
  });

  const listing = data?.getListing;

  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isPricingDetailsModalVisible, setIsPricingDetailsModalVisible] =
    useState(false);
  const map = useRef<GoogleMap | null>(null);
  const [tab, setTab] = useState<boolean>(true);
  const [buttonState, setButtonState] = useState<ButtonStates>({
    logo: false,
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dates, setDates] = useState<string[]>([]);

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const generateDates = () => {
    const array: string[] = [];
    let currentDate = startDate;

    while (currentDate && endDate && currentDate <= endDate) {
      array.push(format(currentDate, "yyyy-MM-dd"));
      currentDate = addDays(currentDate, 1);
    }

    return array;
  };

  useEffect(() => {
    if (startDate && endDate) {
      setDates(generateDates());
    } else {
      setDates([]);
    }
  }, [startDate, endDate]);

  // const oldPrice = dates
  //   ? ((listing?.surgePrice || 0) * (dates.length - 1)).toFixed(2)
  //   : 0;

  // const listingRating = usecase == "create listing" ? 5 : 5;
  // const listingRating = usecase == 'create listing' ? 5 : listing.ListingReviews?.items => listing.ListingReviews?.items.reduce((a, b) => a.rating + b.rating) / listing.ListingReviews?.items.length;
  // const average = array => array.reduce((a, b) => a + b) / array.length

  const iconSize = 20;
  const iconColor = colors.primary;

  const rvAccommodation = () => {
    let tempArray: string[] = []; /* eslint-disable-line prefer-const */
    if (listing?.automobile) tempArray.push("automobiles");
    if (listing?.bigRig) tempArray.push("big rigs");
    if (listing?.fifthWheel) tempArray.push("fifth wheels with tip-outs");
    if (listing?.motorhome) tempArray.push("motorhomes with tip-outs");
    if (listing?.motorhomeTowing)
      tempArray.push("towing motorhomes with tip-outs");
    if (listing?.pickupCamper) tempArray.push("pickup guests with tip-outs");
    if (listing?.tent) tempArray.push("tents");
    if (listing?.tentTrailer) tempArray.push("tent trailers with tip-outs");
    if (listing?.travelTrailer) tempArray.push("travel trailers with tip-outs");
    if (listing?.van) tempArray.push("vans");
    if (listing?.otherCamp) tempArray.push("other vehicles");
    return stringConcatination(tempArray);
  };

  const rodeoAccommodation = () => {
    if (listing?.anyAnimals) {
      return "any animals";
    } else {
      let tempArray: string[] = []; /* eslint-disable-line prefer-const */
      if (listing?.bovine) tempArray.push("bovines");
      if (listing?.caprine) tempArray.push("caprines");
      if (listing?.equine) tempArray.push("equines");
      if (listing?.swine) tempArray.push("swine");
      return stringConcatination(tempArray);
    }
  };

  const rodeoSpecification = () => {
    let tempArray: string[] = []; /* eslint-disable-line prefer-const */
    if (listing?.barn) tempArray.push("barn");
    if (listing?.pasture) tempArray.push("pasture");
    if (listing?.pen) tempArray.push("pen");
    if (listing?.shelter) tempArray.push("shelter");
    return stringConcatination(tempArray);
  };

  const stringConcatination = (array: string[]) => {
    let tempString = "";
    if (array.length == 1) {
      tempString = array[0];
    } else if (array.length == 2) {
      tempString = `${array[0]} and ${array[1]}`;
    } else {
      for (let i = 0; i < array.length - 1; i++) {
        tempString += `${array[i]}, `;
      }
      tempString += `and ${array[array.length - 1]}`;
    }
    return tempString;
  };

  const distance = (coor1: ICoordinate, coor2: ICoordinate) => {
    const x = coor2.latitude - coor1.latitude;
    const y = coor2.longitude - coor1.longitude;
    return Math.sqrt(x * x + y * y);
  };
  const sortByDistance = (listings: any[], point: ICoordinate) => {
    if (!listings) {
      return [];
    }
    const sorter = (a: ICoordinate, b: ICoordinate) =>
      distance(a, point) - distance(b, point);
    return listings.sort(sorter);
  };

  const hostListings =
    data?.getListing?.Host?.UserListings?.items &&
    sortByDistance(
      data?.getListing?.Host?.UserListings?.items.filter(
        (listing: any) =>
          listing?.active &&
          !listing?.Calendar?.unavailableDates.some((item: any) =>
            dates?.includes(String(item))
          )
      ),
      {
        latitude: listing?.latitude || 0,
        longitude: listing?.longitude || 0,
      }
    );

  const [doCreateSave, { error: createSaveError, data: createSaveData }] =
    useMutation<CreateSaveMutation, CreateSaveMutationVariables>(createSave, {
      variables: { input: { userID: userID, listingID: listing?.id || "" } },
      refetchQueries: ["SavesByListing"],
    });

  const { data: usersSavesData } = useQuery<
    SavesByListingQuery,
    SavesByListingQueryVariables
  >(savesByListing, {
    variables: { listingID: listing?.id || "", userID: { eq: userID } },
    fetchPolicy: "cache-and-network",
  });

  const userSave = (usersSavesData?.savesByListing?.items || []).filter(
    (save: any) => !save?._deleted
  )?.[0];

  const [doDeleteSave, { error: deleteSaveError, data: deleteSaveData }] =
    useMutation<DeleteSaveMutation, DeleteSaveMutationVariables>(deleteSave);

  const toggleSave = async () => {
    if (userSave) {
      doDeleteSave({
        variables: { input: { id: userSave.id, _version: userSave._version } },
      });
    } else {
      doCreateSave();
    }
  };

  if (loading) {
    return <Loader />;
  }

  // if (error || !listing) {
  //   return (
  //     <ApiErrorMessage
  //       title="Error fetching listing"
  //       message={error?.message || 'Listing not found'}
  //       onRetry={() => refetch()}
  //     />
  //   );
  // }

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Heading Section */}
      <div className={`${styles.heading}`}>
        <div className={`${styles.headingOuter}`}>
          <div className="d-flex align-items-center">
            <div
              className={`m-2 ${buttonState.logo ? "hovered" : ""}`}
              onMouseEnter={() =>
                setButtonState({ ...buttonState, logo: true })
              }
              onMouseLeave={() =>
                setButtonState({ ...buttonState, logo: false })
              }
            >
              <img
                onClick={() => window.history.back()}
                src={buttonState.logo ? logoBlack : logoOrange}
                alt="H"
                className={`img-fluid ${styles.logo} ${styles.clickable}`}
              />
            </div>

            {["H", "O", "S", "T"].map((letter, index) => (
              <div
                key={index}
                onClick={() => window.history.back()}
                className="m-1"
              >
                <span className={`${styles.logoLetter} ${styles.clickable}`}>
                  {letter}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className={`${styles.headingOuter}`}>
          <div className={`${styles.profileContainer}`}>
            <div className={`${styles.signout} ${styles.clickable}`}>
              <span
                className={`${styles.signoutText}`}
                onClick={() => {
                  Auth.signOut();
                  navigate("/");
                }}
              >
                Sign Out
              </span>
            </div>
            <div
              className={`${styles.profile} ${styles.clickable}`}
              onClick={() =>
                alert("Profile management feature is currently in development!")
              }
            >
              <UserImage
                imageKey={user?.image}
                style={{
                  height: "40px",
                  width: "40px",
                  flex: 1,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Body Section */}
      <div className={`${styles.body}`}>
        {/* Title */}
        <div className={`${styles.titleContainer}`}>
          <span className={`${styles.title}`}>{listing?.title}</span>
        </div>
        {/* Under Title span */}
        <div className={`${styles.underTitleContainer}`}>
          <div className={`d-flex flex-direction-row align-items-center`}>
            <div className={`${styles.subTitleContainer}`}>
              <GrLocation color={colors.black} size={20} />
              <span className={`${styles.subTitle}`}>
                {`${listing?.city}, ${listing?.state}, ${listing?.country}`}
              </span>
            </div>
            <div className={`${styles.subTitleContainer}`}>
              {listing?.listingType == "RODEO" ? (
                <FaHorse color={colors.black} size={20} />
              ) : (
                <FaCampground color={colors.black} size={20} />
              )}
              <span className={`${styles.subTitle}`}>
                {listing?.listingType}
              </span>
            </div>
            <div className={`${styles.subTitleContainer}`}>
              <MdOutlineVerified color={colors.black} size={23} />
              <span className={`${styles.subTitle}`}>
                {`Hosted by ${
                  listing?.Host?.firstName || listing?.Host?.username
                }`}
              </span>
            </div>
          </div>
          <div
            className={`d-flex flex-direction-row align-items-center ${styles.clickable}`}
            onClick={() => toggleSave()}
          >
            <span
              className={`${styles.subTitle} ${userSave && styles.primary}`}
            >
              {userSave ? "Saved" : "Save"}
            </span>
            {userSave ? (
              <MdBookmarkAdded color={colors.primary} size={25} />
            ) : (
              <MdBookmarkBorder color={colors.black} size={25} />
            )}
          </div>
        </div>
        {/* Images */}
        {listing?.images && (
          <div className={`${styles.imagesContainer}`}>
            <div className={`${styles.imageBig}`}>
              <Content
                image={listing?.images[0]}
                style={{
                  width: "100%",
                  minHeight: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              />
            </div>
            <div className={`${styles.imagesSmallContainer}`}>
              <div className={`${styles.imagesSmallTopRow}`}>
                <div className={`${styles.imageSmall}`}>
                  <Content
                    image={listing?.images[1 % listing?.images.length]}
                    style={imageStyle}
                  />
                </div>
                <div className={`${styles.imageTopRight}`}>
                  <Content
                    image={listing?.images[2 % listing?.images.length]}
                    style={imageStyle}
                  />
                </div>
              </div>
              <div className={`${styles.imagesSmallBottomRow}`}>
                <div className={`${styles.imageSmall}`}>
                  <Content
                    image={listing?.images[3 % listing?.images.length]}
                    style={imageStyle}
                  />
                </div>
                <div className={`${styles.imageBottomRight}`}>
                  <Content
                    image={listing?.images[4 % listing?.images.length]}
                    style={imageStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Information */}
        <div className={`${styles.informationContainer}`}>
          {/* Tabs */}
          <div className={`${styles.tabsSection}`}>
            <div className={`d-flex flex-direction-row align-items-center`}>
              <div onClick={() => setTab(true)}>
                <span
                  className={`${styles.tabTitle} ${
                    tab ? styles.primary : styles.medium
                  } ${styles.clickable}`}
                >
                  Overview
                </span>
              </div>
              <div className={`${styles.whatsIncluded}`} />
              <div onClick={() => setTab(false)}>
                <span
                  className={`${styles.tabTitle} ${
                    !tab ? styles.primary : styles.medium
                  } ${styles.clickable}`}
                >
                  What's Included
                </span>
              </div>
            </div>
            {tab ? (
              <div className={`${styles.topSpace}`}>
                {/* Overviews */}
                <div className={`${styles.overviews}`}>
                  {/* Overview Top */}
                  <div className={`${styles.overviewTop}`}>
                    {/* Listing Type */}
                    <div className={`${styles.overviewCol}`}>
                      <div
                        className={`d-flex flex-direction-row align-items-center`}
                      >
                        {listing?.listingType == "RODEO" ? (
                          <FaHorse color={colors.primary} size={20} />
                        ) : (
                          <FaCampground color={colors.primary} size={20} />
                        )}
                        <span className={`${styles.overviewTitleText}`}>
                          {listing?.listingType == "RODEO"
                            ? listing?.rodeoType
                            : listing?.campType}
                        </span>
                      </div>
                      <span className={`${styles.overviewTitleDescription}`}>
                        {listing?.listingType === "RODEO"
                          ? listing?.rodeoType === "Full Boarding"
                            ? "Your host will check on, care for, feed, water, and accommodate your animals traveling with you."
                            : listing?.rodeoType === "Partial Boarding"
                            ? "Similar to “Full Boarding”, with the exception that you are expected to provide a portion of the services. This option still allows you to come and go, only after you provided the required care to your animals."
                            : listing?.rodeoType === "Pasture Boarding"
                            ? "Your animals will be outdoors for the duration of their stay and be provided feed and water."
                            : "A place to rest your animals. You are required to fully care for, feed, and water your animals. You cannot come or go from this listing without your animals."
                          : listing?.campType === "Concrete Pad"
                          ? "Camping area consisting of a concrete pad."
                          : listing?.campType === "Grass Lot"
                          ? "A grass and/or dirt camping area."
                          : "A gravelled camping area."}
                      </span>
                    </div>
                    {/* Nof Nights Stayed */}
                    <div className={`${styles.overviewCol}`}>
                      <div
                        className={`d-flex flex-direction-row align-items-center`}
                      >
                        <FaRegClock color={colors.primary} size={20} />
                        <span className={`${styles.overviewTitleText}`}>
                          {`Stays at this listing: ${
                            listing?.nofNights || 0
                          } nights`}
                        </span>
                      </div>
                      <span className={`${styles.overviewTitleDescription}`}>
                        {(listing?.nofNights || 0) == 0
                          ? `Be the first to stay at this listing!`
                          : (listing?.nofNights || 0) <= 5 &&
                            (listing?.nofNights || 0) > 0
                          ? `Be one of the first to stay at this listing!`
                          : `This listing has a record a positive stays, join the group!`}
                      </span>
                    </div>
                  </div>
                  {/* Overview Bottom */}
                  <div className={`d-flex flex-direction-row`}>
                    {/* Weekly Discount */}
                    {(listing?.weeklyDiscount || 0) > 0 && (
                      <div className={`${styles.overviewCol}`}>
                        <div
                          className={`d-flex flex-direction-row align-items-center`}
                        >
                          <BsCalendar3Week color={colors.primary} size={20} />
                          <span className={`${styles.overviewTitleText}`}>
                            {`Weekly Discount: ${
                              listing?.weeklyDiscount || 0
                            }%`}
                          </span>
                        </div>
                        <span className={`${styles.overviewTitleDescription}`}>
                          {`For long-term travelers staying between 7 and 27 nights, this is your chance to save at least $${Math.round(
                            (listing?.basePrice || 0) *
                              7 *
                              ((listing?.weeklyDiscount || 0) / 100)
                          )}.`}
                        </span>
                      </div>
                    )}

                    {/* Monthly Discount*/}
                    {(listing?.monthlyDiscount || 0) > 0 && (
                      <div className={`${styles.overviewCol}`}>
                        <div
                          className={`d-flex flex-direction-row align-items-center`}
                        >
                          <BsCalendar3 color={colors.primary} size={20} />
                          <span className={`${styles.overviewTitleText}`}>
                            {`Monthly Discount: ${
                              listing?.monthlyDiscount || 0
                            }%`}
                          </span>
                        </div>
                        <span className={`${styles.overviewTitleDescription}`}>
                          {`For long-term travelers staying 28 nights or more, this is your chance to save at least $${Math.round(
                            (listing?.basePrice || 0) *
                              28 *
                              ((listing?.monthlyDiscount || 0) / 100)
                          )}.`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Seperator */}
                <div className={`${styles.seperator}`} />

                {/* Description */}
                <div className={`${styles.bottomSpace}`}>
                  <span className={`${styles.descriptionText}`}>
                    {listing?.description}
                  </span>
                </div>

                {/* Seperator */}
                <div className={`${styles.seperator}`} />

                <div className={`${styles.accommodationContainer}`}>
                  {/* Is this listing for me? */}
                  <span className={`${styles.otherTitle}`}>
                    Is this listing for me?
                  </span>
                  <span className={`${styles.otherSubText}`}>
                    Below are the requirements to stay at this listing
                  </span>

                  {/* Accommodated Title */}
                  <span className={`${styles.accommodationTitle}`}>
                    This listing accommodates the following:
                  </span>

                  {/* Accommodated Parties */}
                  <div className={`${styles.accommodationWrapper}`}>
                    {listing?.listingType == "RODEO" ? (
                      <>
                        <Accommodation
                          label={"Bulls"}
                          available={listing?.bovine || false}
                        />
                        <Accommodation
                          label={"Cows"}
                          available={listing?.bovine || false}
                        />
                        <Accommodation
                          label={"Steers"}
                          available={listing?.bovine || false}
                        />
                        <Accommodation
                          label={"Goats"}
                          available={listing?.caprine || false}
                        />
                        <Accommodation
                          label={"Sheep"}
                          available={listing?.caprine || false}
                        />
                        <Accommodation
                          label={"Horses"}
                          available={listing?.equine || false}
                        />
                        <Accommodation
                          label={"Pigs"}
                          available={listing?.swine || false}
                        />
                        <Accommodation
                          label={"and other animals"}
                          available={listing?.anyAnimals || false}
                        />
                      </>
                    ) : (
                      <>
                        <Accommodation
                          label={"Automobiles"}
                          available={listing?.automobile || false}
                        />
                        <Accommodation
                          label={"Big Rigs"}
                          available={listing?.bigRig || false}
                        />
                        <Accommodation
                          label={"Fifth Wheel"}
                          available={listing?.fifthWheel || false}
                        />
                        <Accommodation
                          label={"Motorhome"}
                          available={listing?.motorhome || false}
                        />
                        <Accommodation
                          label={"Motorhome Towing"}
                          available={listing?.motorhomeTowing || false}
                        />
                        <Accommodation
                          label={"Pickup Campers"}
                          available={listing?.pickupCamper || false}
                        />
                        <Accommodation
                          label={"Tents"}
                          available={listing?.tent || false}
                        />
                        <Accommodation
                          label={"Tent Trailers"}
                          available={listing?.tentTrailer || false}
                        />
                        <Accommodation
                          label={"Travel Trailers"}
                          available={listing?.travelTrailer || false}
                        />
                        <Accommodation
                          label={"Vans"}
                          available={listing?.van || false}
                        />
                        <Accommodation
                          label={"and other camping methods"}
                          available={listing?.otherCamp || false}
                        />
                      </>
                    )}
                  </div>

                  {/* How many can be accommodated? */}
                  <div className={`${styles.bottomSpace}`}>
                    <span className={`${styles.mdNormalBlack}`}>
                      {"and can accommodate up to "}
                    </span>
                    <span className={`${styles.mdBoldPrimary}`}>
                      {listing?.listingType == "RODEO"
                        ? listing?.maxAnimals
                        : listing?.maxGuests}
                    </span>
                    <span className={`${styles.mdNormalBlack}`}>
                      {listing?.listingType == "RODEO"
                        ? ` of them.`
                        : ` guests.`}
                    </span>
                  </div>

                  {/* Camp Size Constraints */}
                  {listing?.listingType == "CAMP" && (
                    <div>
                      <span className={`${styles.mdNormalBlack}`}>
                        {"Vehicles should be able to fit within an area of "}
                      </span>
                      <span className={`${styles.mdBoldPrimary}`}>
                        {listing?.maxWidth}
                      </span>
                      <span className={`${styles.mdNormalBlack}`}>
                        {" feet by "}
                      </span>
                      <span className={`${styles.mdBoldPrimary}`}>
                        {listing?.maxLength}
                      </span>
                      <span className={`${styles.mdNormalBlack}`}>
                        {" feet."}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={`${styles.topSpace}`}>
                {/* Rodeo Facilities */}
                {listing?.listingType == "RODEO" && (
                  <>
                    <div className={`${styles.accommodationContainer}`}>
                      <span className={`${styles.otherTitle}`}>
                        What space will my animals have?
                      </span>
                      <span className={`${styles.otherSubText}`}>
                        Places that your animals will be able to roam
                      </span>
                      <div className={`${styles.accommodationWrapper}`}>
                        <>
                          <Accommodation
                            label={`Pasture`}
                            available={listing?.pasture || false}
                          />
                          <Accommodation
                            label={`Enclosed Pen`}
                            available={listing?.pen || false}
                          />
                          <Accommodation
                            label={`Outdoor Shelter`}
                            available={listing?.shelter || false}
                          />
                          <Accommodation
                            label={`Barn`}
                            available={listing?.barn || false}
                          />
                        </>
                      </div>
                    </div>

                    {/* Seperator */}
                    <div className={`${styles.seperator}`} />
                  </>
                )}

                {/* What amenities are offered? */}
                <div className={`${styles.accommodationContainer}`}>
                  <span className={`${styles.otherTitle}`}>
                    What amenities are offered?
                  </span>
                  <span className={`${styles.otherSubText}`}>
                    Power, water, and extras, what does your host have available
                    to you?
                  </span>

                  {/* Amenities */}
                  <div className={`${styles.accommodationWrapper}`}>
                    {listing?.listingType == "RODEO" ? (
                      <>
                        <Accommodation
                          label={`20 Amp Power (Regular Outlet)`}
                          available={listing?.rodeoPower?.includes(20) || false}
                        />
                        <Accommodation
                          label={`30 Amp Power`}
                          available={listing?.rodeoPower?.includes(30) || false}
                        />
                        <Accommodation
                          label={`50 Amp Power`}
                          available={listing?.rodeoPower?.includes(50) || false}
                        />
                        <Accommodation
                          label={`Potable Water`}
                          available={listing?.rodeoWater || false}
                        />
                        <Accommodation
                          label={`Feed`}
                          available={listing?.feed || false}
                        />
                        <Accommodation
                          label={`Bedding`}
                          available={listing?.bedding || false}
                        />
                        <Accommodation
                          label={`Stalls`}
                          available={listing?.stalls || false}
                        />
                        <Accommodation
                          label={`Special Services`}
                          available={listing?.specialServices || false}
                        />
                      </>
                    ) : (
                      <>
                        <Accommodation
                          label={`20 Amp Power (Regular Outlet)`}
                          available={listing?.campPower?.includes(20) || false}
                        />
                        <Accommodation
                          label={`30 Amp Power`}
                          available={listing?.campPower?.includes(30) || false}
                        />
                        <Accommodation
                          label={`50 Amp Power`}
                          available={listing?.campPower?.includes(50) || false}
                        />
                        <Accommodation
                          label={`Potable Water`}
                          available={listing?.campWater || false}
                        />
                        <Accommodation
                          label={`Sewage Hookup`}
                          available={listing?.sewage || false}
                        />
                        <Accommodation
                          label={`Firewood`}
                          available={listing?.firewood || false}
                        />
                        <Accommodation
                          label={`Wifi`}
                          available={listing?.wifi || false}
                        />
                        <Accommodation
                          label={`Cable TV`}
                          available={listing?.cableTV || false}
                        />
                      </>
                    )}
                  </div>
                </div>

                {/* Seperator */}
                <div className={`${styles.seperator}`} />

                {/* Rules */}
                <div className={`${styles.accommodationContainer}`}>
                  <span className={`${styles.otherTitle}`}>
                    Are there any rules?
                  </span>

                  <span className={`${styles.otherSubText}`}>
                    Rules that the host expects guests to follow
                  </span>

                  <div className={`${styles.accommodationWrapper}`}>
                    <>
                      <Accommodation
                        label={`Surveillance Used`}
                        available={listing?.surveillance || false}
                      />
                      <Accommodation
                        label={`Smoking Allowed`}
                        available={listing?.smoking || false}
                      />
                      <Accommodation
                        label={`Campfires Allowed`}
                        available={listing?.campfires || false}
                      />
                      <Accommodation
                        label={`Pets Allowed`}
                        available={listing?.pets || false}
                      />
                      <Accommodation
                        label={`Parties Allowed`}
                        available={listing?.party || false}
                      />
                      <Accommodation
                        label={`Curfew`}
                        available={listing?.pets || false}
                      />
                      <Accommodation
                        label={`Pets Allowed`}
                        available={listing?.pets || false}
                      />
                    </>
                  </div>
                </div>

                {listing?.additionalRules && (
                  <>
                    {/* Seperator */}
                    <div className={`${styles.seperator}`} />

                    {/* Rules */}
                    <span className={`${styles.otherTitle}`}>
                      {`Message from ${
                        listing?.Host?.firstName || listing?.Host?.username
                      }`}
                    </span>

                    <div className={`${styles.bottomSpace}`}>
                      <span className={`${styles.descriptionText}`}>
                        {listing?.additionalRules}
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {/* Pricing */}
          <div className={`${styles.pricingSection}`}>
            <div className={`${styles.pricingCard}`}>
              <div className={`${styles.pricingContainer}`}>
                <span className={`${styles.xlgNormalBlack}`}>
                  ${Math.round(Number(listing?.basePrice))}
                </span>
                <span className={`${styles.surgePrice}`}>
                  ${listing?.surgePrice}
                </span>
                <span className={`${styles.pricePerNight}`}>
                  price per night
                </span>
              </div>
              <div className={`${styles.dateSection}`}>
                <div className={`${styles.dateContainerLeft}`}>
                  <span className={`${styles.sBoldBlack}`}>Check In</span>
                  {/* <span className={`${styles.mdNormalBlack}`}>Select Date</span> */}
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select Checkin"
                  />
                </div>
                <div className={`${styles.dateContainerRight}`}>
                  <span className={`${styles.sBoldBlack}`}>Check Out</span>
                  {/* <span className={`${styles.mdNormalBlack}`}>Select Date</span> */}
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select Checkout"
                  />
                </div>
              </div>
              <Button
                className={`${styles.button}`}
                // onClick={}
              >
                Reserve
              </Button>
            </div>
            <div className={`${styles.mapCard}`}>
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                center={{
                  lat: listing?.latitude || 0,
                  lng: listing?.longitude || 0,
                }}
                options={{
                  maxZoom: 15,
                }}
                zoom={10}
              >
                <MarkerF
                  position={{
                    lat: listing?.latitude || 0,
                    lng: listing?.longitude || 0,
                  }}
                />
              </GoogleMap>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
