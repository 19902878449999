import image from "../../assets/wagon_wheel.svg";
import styles from "./Loader.module.css";

export const Loader = () => {
  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <div className={`${styles.rotatingImageContainer}`}>
        <img className={`${styles.rotatingImage}`} src={image} alt="O" />
      </div>
    </div>
  );
};
