import { Link } from "react-router-dom";
import { FaHorse, FaCampground } from "react-icons/fa";
import colors from "../../theme/colors";
import styles from "./ListingCard.module.css";
import { Content } from "../Content/Content";

interface IListingCard {
  basePrice: number;
  city: string;
  state: string;
  country: string;
  dates?: string[];
  images: string[];
  listingID: string;
  listingType: string;
  monthlyDiscount: number;
  surgePrice: number;
  surgePriceDates: (string | null)[];
  title: string;
  usecase: string;
  weeklyDiscount: number;
}

const imageStyle: React.CSSProperties = {
  width: "100%",
  minHeight: "100%",
};

const ListingCard = ({
  basePrice,
  city,
  state,
  country,
  dates,
  images,
  listingID,
  listingType,
  monthlyDiscount,
  surgePrice,
  surgePriceDates,
  title,
  usecase,
  weeklyDiscount,
}: IListingCard) => {
  const oldPrice = dates ? (surgePrice * (dates.length - 1)).toFixed(2) : 0;

  return (
    <Link
      to={`/${listingID}`}
      className={`${styles.cardContainer}`}
    >
      <div className={`${styles.imageContainer}`}>
        <Content image={images[0]} style={imageStyle} />
      </div>
      <div className={`${styles.infoContainer}`}>
        <div className={`${styles.locationTypeContainer}`}>
          <span
            className={`${styles.normalBlack}`}
          >{`${city}, ${state}, ${country}`}</span>
          <div className={`${styles.typeContainer}`}>
            {listingType == "CAMP" ? (
              <FaCampground color={colors.black} size={15} />
            ) : (
              <FaHorse color={colors.black} size={15} />
            )}
            <span className={`${styles.type}`}>{listingType}</span>
          </div>
        </div>
        <div className={`${styles.datePriceContainer}`}>
          <div className={`${styles.priceContainer}`}>
            <span className={`${styles.surgePrice}`}>${surgePrice}</span>
            <span className={`${styles.xlgBlack}`}>
              ${Math.round(Number(basePrice))}
            </span>
          </div>
          <span className={`${styles.normalBlack} ${styles.perNight}`}>
            per night
          </span>
        </div>
      </div>
    </Link>
  );
};

export default ListingCard;
