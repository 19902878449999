import { BiX, BiCheck } from "react-icons/bi";
import colors from "../../theme/colors";
import fonts from "../../theme/fonts";
import styles from "./Accommodation.module.css";

interface IAccommodation {
  label: string;
  available: boolean;
}

export const Accommodation = ({ label, available }: IAccommodation) => {
  return (
    <div className={`${styles.container}`}>
      <span
        className={`${styles.label} ${
          available == undefined
            ? styles.primary
            : available
            ? styles.black
            : styles.gray
        }`}
      >
        {label}
      </span>
      {available !== undefined && (
        <>
          <div className={`${styles.available}`} />
          {available ? (
            <BiCheck color={colors.primary} size={20} />
          ) : (
            <BiX color={colors.gray} size={20} />
          )}
        </>
      )}
    </div>
  );
};
