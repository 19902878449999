import { Row, Col, Container, Stack } from "react-bootstrap";
import logoOrange from "../../assets/host_logo_negative_orange.png";
import styles from "./PrivacyPage.module.css";
import { Footer } from "../../components/Footer/Footer";

export function Privacy() {
  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <Row xs={3} className={`align-items-center mb-4 ${styles.header}`}>
        <Col>
          <Row>
            <div className="d-flex align-items-center">
              <div className="m-2">
                <img
                  onClick={() => window.history.back()}
                  src={logoOrange}
                  alt="H"
                  className={`img-fluid ${styles.logo} ${styles.logoContainer}`}
                />
              </div>

              {["P", "r", "i", "v", "a", "c", "y"].map((letter, index) => (
                <div key={index} onClick={() => window.history.back()}>
                  <span className={`${styles.logoLetter}`}>{letter}</span>
                </div>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
      {/* Body */}
      <div className="flex-grow-1 d-flex justify-content-center">
        <Container fluid>
          <Stack gap={2} direction="vertical" className={`align-items-center`}>
            <div className={`${styles.titleContainer}`}>
              <span className={`${styles.title}`}>Privacy Policy</span>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  This is the Privacy Policy for the hosting and space sharing
                  mobile application (the “App”) offered by Host, Inc. (“Host,”
                  “us,” or “we”). Host understands that your privacy is
                  important to you. As a valued member of the Host community, we
                  offer this Privacy Policy so you can understand how and why we
                  collect your information and your options to control your
                  privacy when using the App. This Privacy Policy is governed by
                  and is part of our Terms and Conditions.
                </span>
              </div>

              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  If you have questions, please contact Host at{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>
                  .
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  1. Your Personal Information on Host
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  As used in this Privacy Policy, “Personal Information” means
                  any information that identifies, relates to, describes, is
                  reasonably capable of being associated with, or could
                  reasonably be linked, directly or indirectly, with a
                  particular consumer or household. Personal Information falls
                  within certain categories, for example identifiers (e.g., your
                  contact information or username), employment or non-public
                  education information, legally protected information (e.g.,
                  citizenship, marital status, sex); “sensitive” information
                  (e.g., payment information, race, religion, health data),
                  biometrics (e.g., fingerprints, face or voice print);
                  commercial information like your purchasing history, your
                  online activities, or inferences drawn from this Personal
                  Information and used to create a profile about your
                  preferences, characteristics, trends, predispositions,
                  behavior, attitudes, intelligence, or aptitudes.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Note, however, that information about you that is publicly
                  available, anonymized, or aggregated (meaning data summaries
                  or reports with Personal Information removed) is generally not
                  considered Personal Information or protected by privacy laws.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    a) Information We Collect and How We Use It:
                  </span>{" "}
                  Host collects Personal Information from you if you use the App
                  as a listing host or as a guest reserving to use a listing. We
                  collect identifiers, commercial information, and internet
                  activity either with your consent (as informed by this Privacy
                  Policy and freely given at the time you provide the
                  information), to fulfill a legitimate interest, or as
                  authorized or required by law. We only collect, use, retain,
                  and disclose Personal Information as is adequate and relevant
                  to the specific, express purposes described below or as
                  reasonably necessary and proportionate to allow you to use the
                  App. More specifically, Host collects:
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    i) Your profile information
                  </span>{" "}
                  when you signup and create your profile on the App. We collect
                  your username, first and last name, email address, and a
                  password to secure your account. You consent to Host
                  collecting this information about you, and we use it to
                  facilitate your use of the App and to communicate with you
                  about the App and your reservations or listings.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    ii) Reservation details
                  </span>{" "}
                  including the dates, location, guest list, and reservation
                  type. Host also keeps a record of your commercial information
                  on the App like listings you have saved to view later, your
                  reservation history, and search history. We collect this
                  information with your consent, and we use it to facilitate
                  your use of the App.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>iii) Chats</span> in which
                  you participate using our chat feature on the App. If you use
                  the chat feature, we will receive any Personal Information
                  that you include in your messages. Your use of the chat
                  feature is optional, and any Personal Information you include
                  in a chat is collected and used with your consent.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    iv) Your messages to us
                  </span>
                  , for example if you request information about the App or to
                  discuss your options to work with Host. If you contact us for
                  any reason, we will collect your contact information and any
                  other Personal Information that you include in your message.
                  You provide this Personal Information with your consent and we
                  only use it to respond to your message.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    v) Your app activities
                  </span>
                  , such as your device information (e.g., device ID, IP
                  address, operating system, browser type, mobile network
                  information), content interactions and other use details,
                  metadata, and data collected via cookies and related
                  technologies we use to support the App’s features. Host may
                  collect this information to achieve our legitimate interest in
                  providing and improving the App.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Additionally, Host might use Personal Information to monitor
                  your compliance with our agreements, protect your privacy or
                  enforce this Privacy Policy, to comply with the law or a court
                  order, as we deem necessary related to a legal action, related
                  to a business transition of our company, or for any other
                  purpose to which you consent.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>b) 18+ Only:</span> Host
                  provides the App for adults, not children. No individual under
                  the age of 18 should attempt to use the App or provide any
                  Personal Information to Host. Adults using the App to book
                  reservations with children in their party may only submit a
                  child’s Personal Information with parent or legal guardian
                  consent. If we learn we have collected or received Personal
                  Information from a child under 18 without parent or legal
                  guardian consent, we will delete that information.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    c) Retaining Personal Information:
                  </span>{" "}
                  Host only retains Personal Information for the minimum period
                  necessary to provide our Services or achieve our business
                  goals. For example, profile and account information is
                  retained while your account is active and deleted when you
                  delete your account. However, Host will retain the Personal
                  Information necessary to comply with our IRS reporting
                  requirements for 7 years. Cookie data is retained for as
                  little as a few minutes or as long as 24 months, depending on
                  the cookie’s purpose and function. Host reserves the right to
                  retain data for longer periods as required by law or court
                  order or if doing so is critical to our business. Personal
                  Data is securely deleted at the end of its retention period.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>d) Disclosures:</span> Host
                  only discloses Personal Information to the third parties as
                  described below or if you give us permission to disclose it or
                  if we are required by law to do so. In the preceding 12
                  months, we have disclosed Personal Information to:
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>i) The listing host</span>{" "}
                  about you as a guest reserving their listing. If you make a
                  reservation, the listing host will receive your name, a method
                  to contact you, your party size, and other general information
                  to facilitate your use of the listing.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    ii) Our service providers
                  </span>{" "}
                  like data hosting providers, payment processors, and email
                  hosting services. We prohibit our service providers from
                  selling or disclosing the Personal Information we provide, and
                  we require all service providers to maintain confidentiality
                  standards and appropriate technical and organizational
                  measures to ensure the security of your Personal Information.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>iii) Law enforcement</span>{" "}
                  and other government agencies as permitted or required by law.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>iv)</span> In some cases,
                  other third parties for example: if we go through a merger,
                  acquisition, or other business transition, to comply with a
                  legal requirement or a court order, if we believe it is
                  appropriate in response to illegal activities or to prevent
                  fraud or harm, to exercise or defend our legal claims, or for
                  any other reason with your consent.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>v)</span> We reserve the
                  right to share aggregated, anonymized, or deidentified
                  information about any individuals with nonaffiliated entities
                  for marketing, advertising, research or other purposes,
                  without restriction.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  2. Your Controls and Options
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  The App allows you certain options to directly control the
                  Personal Information we collect and hold about you. You can:
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    a) Update Your Profile:
                  </span>{" "}
                  Through your App login you can access, correct/update, or
                  delete certain Personal Information.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    b) Change Your Email Preferences
                  </span>{" "}
                  by clicking to unsubscribe or change your preferences via the
                  links provided in the emails. Note that if you opt out of
                  marketing communications, we may still send you
                  non-promotional communications, such as those about your
                  listing or reservation.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    c) Adjust Your Device Settings
                  </span>{" "}
                  or browser settings to control the data we collect through
                  cookies and related technologies.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    d) Block Location Tracking:
                  </span>{" "}
                  You can stop all collection of information by an app by
                  uninstalling it. You can also reset your device Ad Id at any
                  time through your device settings, which is designed to allow
                  you to limit the use of information collected about you. You
                  can stop all collection of precise location data through an
                  app by uninstalling the app or withdrawing your consent
                  through your device settings. However, key features of the App
                  will not function if you block location tracking.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>e) Do Not Track:</span> Do
                  Not Track signals are signals sent through a browser informing
                  us that you do not want to be tracked. Currently, our systems
                  do not recognize browser “do-not-track” requests.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>f) Privacy Requests:</span>{" "}
                  If you have questions, concerns, wish to revoke your consent,
                  or want to exercise your rights under privacy laws (see
                  Section 3), you can submit a privacy request to Host at{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>
                  . We can only assist with or fulfill a privacy request when we
                  have sufficient information to verify that the requester is
                  the person or an authorized representative of the person about
                  whom we have collected Personal Information, and to properly
                  understand, evaluate, and respond to the request. We do not
                  charge a fee to process or respond to a verifiable request
                  unless we have legal grounds to do so. If we determine that
                  the request warrants a fee, we will tell you why we made that
                  decision and provide you with a cost estimate before
                  completing your request. We endeavor to respond to privacy
                  requests in accordance with the requirements of the law
                  applicable to your jurisdiction. If we do not fulfill your
                  request within the legally required timeline, you can appeal
                  our response by contacting{" "}
                  <span className={`${styles.primary}`}>
                    support@hosttravel.org
                  </span>
                  .
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  3. Privacy Rights
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You may be entitled to additional controls over your Personal
                  Information depending on your citizenship or residency. Please
                  note that the privacy law applicable to your jurisdiction may
                  not apply to our company or our business practices.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    a) U.S. Privacy Rights:
                  </span>{" "}
                  U.S. law governs consumer privacy at the federal level related
                  to specific industries or data uses while some individual
                  states offer consumer privacy protections that may apply more
                  generally. This section provides informational notices for
                  state privacy laws like those governing consumer privacy in
                  California, Colorado, Connecticut, Iowa, Nevada, Utah,
                  Virginia, and other states require companies to inform
                  consumers about their privacy rights and provide a method to
                  exercise those rights. Residents of states offering privacy
                  protections (each a “Consumer”) can exercise their privacy
                  rights as applicable to our Services by submitting a Privacy
                  Request. Host will respond to privacy requests to the extent
                  required by law.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>i) Right to Correct.</span>{" "}
                  In most cases, you can correct your Personal Information by
                  updating your App profile. If you become aware that the
                  Personal Information that we hold about you is incorrect and
                  you need help updating it, please inform us and we will update
                  our records.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    ii) Right to Deletion.
                  </span>{" "}
                  You may have the right to request that we delete your Personal
                  Information that we collected and retained, with certain
                  exceptions. If you request deletion, we may permanently
                  delete, deidentify, or aggregate the Personal Information in
                  response.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    iii) No Selling or Sharing.
                  </span>{" "}
                  Host does not sell your Personal Information or share your
                  Personal Information with third parties for cross-contextual
                  behavioral advertising purposes.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    iv) No Sensitive Personal Information.
                  </span>{" "}
                  Host does not seek to collect sensitive Personal Information
                  about any individual, and in no case do we disclose any
                  sensitive Personal Information for the purpose of inferring
                  characteristics about you or otherwise use your sensitive
                  Personal Information without your consent.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>v) No Profiling.</span>{" "}
                  Host does not process your Personal Information to evaluate,
                  analyze, or predict your interests and preferences or
                  otherwise use automated profiling to produce significant
                  effects that concern you.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>vi) Right to Access.</span>{" "}
                  You can request confirmation that we have collected Personal
                  Information about you and that we provide you with access to
                  that Personal Information. If you submit an access request, we
                  will provide you with copies of the requested pieces of
                  Personal Information in a portable and readily usable format.
                  Please note that we may be prohibited by law from disclosing
                  certain pieces of Personal Information, and we may be limited
                  in the number or frequency of requests we must fulfill.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    vii) Right to Disclosure.
                  </span>{" "}
                  You may have the right to request that we disclose information
                  to you about our collection and use of your Personal
                  Information, such as: (A) the categories of Personal
                  Information we have collected about you; (B) the categories of
                  sources for the Personal Information we have collected about
                  you; (C) our business purpose for collecting, using,
                  processing, sharing or selling that Personal Information, as
                  applicable; (D) the categories of third parties with whom we
                  share that Personal Information; and (E) if we sold or shared
                  your Personal Information under the applicable privacy laws,
                  two separate lists stating: (1) sales or sharing, identifying
                  the Personal Information categories that each category of
                  recipient purchased; and (2) disclosures for a business
                  purpose, identifying the Personal Information categories that
                  each category of recipient obtained. Some laws may limit the
                  number or frequency of requests we must fulfill.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    viii) Right to Nondiscrimination.
                  </span>{" "}
                  We will not discriminate against you for exercising your
                  privacy rights. For example, if you exercise your privacy
                  rights, unless permitted by law we will not deny you goods or
                  services, charge you different prices, provide you a different
                  level of service, suggest that you will receive a different
                  price or quality of service, or retaliate against you as an
                  employee, applicant for employment, or independent contractor
                  for exercising your privacy rights.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    ix) California Right to Disclosure of Marketing Information.
                  </span>{" "}
                  California’s Shine the Light Act (Civil Code sections
                  1798.83-1798.84) entitles California residents to request
                  certain disclosures regarding Personal Information sharing
                  with affiliates and/or third parties for marketing purposes.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  If you are a Consumer, you may exercise your privacy rights by
                  submitting a Privacy Request. Only you or someone legally
                  authorized to act on your behalf may make a verifiable Privacy
                  Request related to your Personal Information. You may also
                  make a verifiable privacy request on behalf of your minor
                  child. You may designate a third party to exercise your rights
                  – an authorized agent – however we will require written proof
                  of the authorization and potentially proof of your identity.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    b) Canadian Privacy Rights:
                  </span>{" "}
                  This section provides the disclosures and notices required
                  under Canada’s Personal Information Protection and Electronic
                  Documents Act (“PIPEDA”) and solely to residents of Canada
                  where PIPEDA applies (“Canadian Consumers”). PIPEDA gives
                  Canadian Consumers specific rights regarding Personal
                  Information offering details on an identifiable person without
                  the inclusion of name, title, telephone number, and business
                  address of an employee of a business or organization. The
                  rights afforded under PIPEDA are described below.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>i) Right to know</span> why
                  we collect, use, and distribute the Personal Information we
                  process. We have set the required notices in this Privacy
                  Policy. We may provide you with additional notices about other
                  ways we process your Personal Information, such as by sending
                  you a notice via email or by other means of communication.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>ii) Right to expect</span>{" "}
                  us to collect, use, or disclose Personal Information
                  responsibly and not for any other purpose other than which you
                  consented. We set your expectations in this Privacy Policy and
                  collect express or implied consent at various stages of
                  collection or processing. If we collect or use your Personal
                  Information based on your consent, we will also notify you of
                  any changes and will request your further consent as needed.
                  You may withdraw your consent at any time with reasonable
                  notice by submitting a privacy request.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    iii) Right to accuracy
                  </span>{" "}
                  of your Personal Information. We take steps to reasonably
                  ensure that your Personal Information we are using is
                  accurate. If you become aware that the Personal Information
                  that we hold about you is incorrect, or if your information
                  changes, please inform us and we will update our records.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>iv) Right to access</span>{" "}
                  your Personal Information. Upon written request and identity
                  authentication, we will provide you with your Personal
                  Information under our control, information about the ways in
                  which that information is being used and a description of the
                  individuals and organizations to whom that information has
                  been disclosed. We will make the information available within
                  30 days or provide written notice where additional time is
                  required to fulfil the request. We may not be able to provide
                  access to some or all of the Personal Information you request
                  if limited by law or potential infringement of another’s
                  privacy rights. If we must refuse an access request, we will
                  notify you in writing, document the reasons for refusal, and
                  outline further steps that are available to you.
                </span>
              </div>

              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Canadian Consumers may exercise these rights submitting a
                  privacy request to Host.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    c) EEA Privacy Rights:
                  </span>{" "}
                  This section provides a courtesy list of disclosures and
                  notices relating to the General Data Protection Regulation and
                  its counterpart regulation applicable to residents of the
                  United Kingdom (“GDPR”). We currently offer the App for use in
                  the United States and Canada only. Users from other
                  jurisdictions that may incidentally access the App do so at
                  their own risk and expressly consent to the privacy practices
                  as described in this Privacy Policy, within the App, and
                  through our Terms and Conditions. Host does not represent that
                  the App is configured to comply with GDPR requirements or that
                  it is appropriate for use by anyone outside of the U.S. or
                  Canada. For informational purposes only, the GDPR provides
                  data subjects with the following privacy rights:
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>i) Right to know</span> how
                  we process your Personal Information. We have set the required
                  notices in this Privacy Policy. We may provide you with
                  additional notices about other ways we process your Personal
                  Information by sending you a notice via email or by another
                  method.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>ii) Right to access</span>{" "}
                  your Personal Information by receiving a copy of the Personal
                  Information you request and details about the types of
                  Personal Information processed, purpose for processing it, and
                  any third parties used to collect Personal Information on our
                  behalf.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    iii) Right to restrict processing
                  </span>{" "}
                  of your Personal Information if: (A) the data is inaccurate;
                  (B) the processing is unlawful; (C) the information is no
                  longer needed; or (E) you exercise your right to object.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>iv) Right to rectify</span>{" "}
                  or correct incorrect Personal Information.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    v) Right to data portability.
                  </span>{" "}
                  In some circumstances, a business is required to provide your
                  Personal Information to another organization at your request
                  and in a structured, commonly used and machine-readable
                  format.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>vi) Right to erasure</span>{" "}
                  (a.k.a. the “right to be forgotten”). You have the right to
                  request deletion of your Personal Information in certain
                  circumstances and where required by law, with exceptions.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>vii) Right to object</span>{" "}
                  to certain processing of your Personal Information. You can
                  request that a business limit its processing of your Personal
                  Information in certain circumstances and where we are required
                  to do so by law.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    viii) Right not to be subject to automated decision-making.
                  </span>{" "}
                  Host does not use automated decision-making (“ADM”) related to
                  the App. If a business uses ADM in certain ways, it must
                  provide a method for data subjects to opt-out of this
                  processing. a data subject may opt-out of this processing.
                </span>
              </div>
              <div className={`${styles.paragraph} ${styles.indent}`}>
                <span className={`${styles.subText}`}>
                  <span className={`${styles.bold}`}>
                    ix) Right to lodge a complaint with a supervisory authority.
                  </span>{" "}
                  Data subjects can submit requests, questions, or complaints by
                  submitting a privacy request to the business. After doing so,
                  if the privacy issue is unresolved, the data subject may file
                  a complaint with a supervisory authority, for example the Data
                  Protection Commissioner of Ireland.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  4. Owned and Operated in the U.S.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Host, Inc. is a United States company using technical
                  infrastructure in the United States and other countries. Host
                  does not warrant that the App is appropriate or authorized for
                  use in any other jurisdictions. You are solely responsible for
                  determining whether their use of the Services complies with
                  applicable laws. If you use the App from outside the United
                  States, please be aware that your Personal Information may be
                  transferred to, processed, stored, and used in the United
                  States or other jurisdictions. If your Personal Information is
                  transferred to the United States, it may be accessed by
                  government authorities under United States law. By allowing us
                  to collect Personal Information about you, you consent to the
                  transfer and processing of your Personal Information as
                  described in this section.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  5. App Security
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  Our data security measures are appropriate to the volume,
                  scope, and nature of the Personal Information processed and
                  designed to meet our duty of care with respect to your
                  Personal Information. We also limit personnel access to
                  Personal Information on an as needed basis. We have
                  implemented and maintain reasonable security measures to
                  secure your Personal Information from accidental loss and
                  unauthorized access, use, alteration, and disclosure.
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  You are responsible for keeping your device access and login
                  information confidential. You are also encouraged to install
                  anti-virus and anti-malware software on your devices and keep
                  all software updated to avoid security risks. We cannot
                  guarantee the security of information you submit via our
                  Services while it is in transit over the Internet, and any
                  such submission is at your own risk.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  6. Scope of Privacy Policy
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  This Privacy Policy applies only to your use of the App and
                  your communications with Host. It does not apply to any
                  applications, websites, or other online services or products
                  offered by a third party, even if accessible from the App or
                  through Host.
                </span>
              </div>
            </div>

            <div className={`${styles.subSection}`}>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText} ${styles.bold}`}>
                  7. Updates to this Privacy Policy
                </span>
              </div>
              <div className={`${styles.paragraph}`}>
                <span className={`${styles.subText}`}>
                  We may update this Privacy Policy from time to time. You can
                  see when this Privacy Policy was last updated by checking the
                  “last updated” date displayed at the top of this page. We will
                  collect your renewed consent to updates to the extent required
                  by applicable law.
                </span>
              </div>
            </div>
          </Stack>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
