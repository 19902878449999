import { Row, Col, Container, Stack } from "react-bootstrap";
import { useState } from "react";
import logoOrange from "../../assets/host_logo_negative_orange.png";
import styles from "./SupportPage.module.css";
import { Footer } from "../../components/Footer/Footer";

export function Support() {
  const [view, setView] = useState<string>("questions");

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <Row xs={3} className={`align-items-center mb-4 ${styles.header}`}>
        <Col>
          <Row>
            <div className="d-flex align-items-center">
              <div className="m-2">
                <img
                  onClick={() => window.history.back()}
                  src={logoOrange}
                  alt="H"
                  className={`img-fluid ${styles.logo} ${styles.logoContainer}`}
                />
              </div>

              {["S", "u", "p", "p", "o", "r", "t"].map((letter, index) => (
                <div key={index} onClick={() => window.history.back()}>
                  <span className={`${styles.logoLetter}`}>{letter}</span>
                </div>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
      {/* Body */}
      <div className="flex-grow-1 d-flex justify-content-center">
        <Container fluid>
          <Stack gap={2} direction="vertical" className={`align-items-center`}>
            <div className={`${styles.titleContainer}`}>
              <span className={`${styles.title}`}>Hi, how can we help?</span>
            </div>

            <div className={`${styles.navigationContainer}`}>
              <span
                className={`${
                  view === "questions"
                    ? styles.selectedNavigator
                    : styles.unselectedNavigator
                } ${styles.navigator}`}
                onClick={() => setView("questions")}
                style={{ cursor: "pointer" }}
              >
                Common Questions
              </span>
              <span
                className={`${
                  view === "contact"
                    ? styles.selectedNavigator
                    : styles.unselectedNavigator
                } ${styles.navigator}`}
                onClick={() => setView("contact")}
                style={{ cursor: "pointer" }}
              >
                Contact Support
              </span>
              <span
                className={`${
                  view === "learn"
                    ? styles.selectedNavigator
                    : styles.unselectedNavigator
                } ${styles.navigator}`}
                onClick={() => setView("learn")}
                style={{ cursor: "pointer" }}
              >
                Learn About Hosting
              </span>
            </div>

            {view === "questions" && (
              <>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      What is{" "}
                      <span
                        className={`${styles.subTitle} ${styles.primary} ${styles.bold}`}
                      >
                        HOST
                      </span>
                      ?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      is more than just a travel platform; it's a mission. It's
                      a marketplace where your journey meets adventure, offering
                      a new way to experience the great outdoors. Founded by
                      three kindred spirits—Caleb, Soren, and Ben—
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      is driven by the spirit of adventure and a deep connection
                      to rural life.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      At its core,{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      is a community of travelers and hosts who value safe,
                      secure, affordable, and exciting travel experiences.
                      Whether you're an RV enthusiast, a camping aficionado, or
                      a traveler with livestock,{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      welcomes you.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Our listings range from camping spots for outdoor
                      enthusiasts to barns, pastures, and pens for those
                      journeying with livestock. What sets{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      apart is that it offers both camping and rodeo listings on
                      private land, with complete transparency and no hidden
                      fees (except for state taxes).
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Your privacy and security are our top priorities. We
                      believe in your right to travel and host with confidence.{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      is united by values of freedom, privacy, and community,
                      and it's built by regular Americans looking to build a
                      trusted network.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      As we grow, we aim to expand from the Northwest to all 50
                      states, offering camping sites in state parks, national
                      parks, and major festivals. We'll also introduce unique
                      experiences and permanent residences for short- and
                      long-term stays.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      is a place to embark on a journey that's all about
                      adventure, privacy, and community. Discover a new way to
                      travel, where your safety and security are our utmost
                      priorities. Join us on this adventure and reimagine travel
                      as you know it.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      In summary,{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      is a platform where travel meets adventure, founded by
                      individuals who share a deep connection to rural life and
                      the great outdoors. It's a community of like-minded
                      travelers and hosts who value safe, secure, and affordable
                      travel experiences.
                    </span>
                  </div>
                </div>

                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      What is a Host?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      A Host can be anyone looking to make some passive income
                      or start their own business by “Hosting” a place for
                      someone to camp, park for the night, or board their
                      livestock.{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      offers a variety of choices for the Host to choose from
                      that suits your specific listing. Whether you have a
                      concrete pad for guests, a grass field for tents, or a
                      barn for cattle to stretch their legs,{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      makes the most of your property.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      How to use{" "}
                      <span
                        className={`${styles.subTitle} ${styles.primary} ${styles.bold}`}
                      >
                        HOST
                      </span>{" "}
                      as a guest?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Traveling in a van? Pulling a Horse Trailer? Looking for a
                      spot to pitch your Tent for the night? Pulling a horse
                      trailer with your van and looking for a camping spot? Host
                      offers it all, simply open the app, search for a listing,
                      and book!
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      What is{" "}
                      <span
                        className={`${styles.subTitle} ${styles.primary} ${styles.bold}`}
                      >
                        HOST
                      </span>{" "}
                      Rodeo?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      Rodeo was built with the rodeo community in mind. With
                      many years of traveling to rodeo’s with animals, we know
                      how expensive and inconvenient finding a place to stay is.{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      Rodeo aims to help improve this experience by helping
                      Hosts in the rural/rodeo communities offer their unique
                      facilities to the traveling rancher, farmer, rodeo star,
                      or just someone who owns livestock, but has nowhere to
                      house them.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      How do I become a Host?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Refer to the "Learn About Hosting" section of this page.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      Why hasn’t my listing been booked yet?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      1. Check to make sure your listing is complete and
                      published.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      2. Are all of your amenities listed? How are your
                      pictures? Is your description detailed? Travelers are more
                      likely to stay with a Host that has a detailed listing.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      3. Consider adding sought after amenities like ‘Water or
                      Electrical Hookups’. Listings with these options are more
                      often booked.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      4. If you are still not getting any stays, consider
                      lowering your price. Does your price properly reflect your
                      listing and make it desirable for people to book?
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      How much money will I make as a Host?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      The amount of income a Host will recieve varies by
                      listing, but there is no limit on how much you can make.
                      There are no hidden fees,{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      charges a flat fee of $4 per night, plus 3% out of every
                      transaction (our payment provider credit card fee). The
                      ‘Guest’ pays additional state and local sales taxes.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      Will I be ‘taxed’ on income made off of{" "}
                      <span
                        className={`${styles.subTitle} ${styles.primary} ${styles.bold}`}
                      >
                        HOST
                      </span>
                      ?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Yes, Uncle Sam always gets a cut (sadly), and income made
                      from Hosting will be taxed the same as any other income
                      (depending on your state).
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      Do I need insurance?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      insurance will cover any damage done by guests during
                      their stay . Be sure to submit any claims through the{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      app at{" "}
                      <span className={`${styles.primary}`}>
                        support@hosttravel.org
                      </span>
                      . Check with your homeowners insurance to be sure that you
                      are covered if a Guest is injured at your property during
                      their stay.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      Does Elon Musk use{" "}
                      <span
                        className={`${styles.subTitle} ${styles.primary} ${styles.bold}`}
                      >
                        HOST
                      </span>
                      ?
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      While we can’t confirm or deny that he does, it is rumored
                      that you can book a listing on Mars, be sure to search for
                      this listing and book it while it’s still available.
                    </span>
                  </div>
                </div>
              </>
            )}

            {view === "contact" && (
              <div className={`${styles.subSection}`}>
                <div className={`${styles.paragraph}`}>
                  <span className={`${styles.subText}`}>
                    If you have questions or concerns, please contact us at{" "}
                    <span className={`${styles.primary}`}>
                      support@hosttravel.org
                    </span>
                    .
                  </span>
                </div>
              </div>
            )}

            {view === "learn" && (
              <>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      1. Becoming a Host
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      To become a Host with{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>
                      , you will first need to complete our identity
                      verification process through Stripe Identity. This happens
                      when you sign up, so no action is required by you. This
                      ensures that everyone using our platform is who they claim
                      to be.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Currently, you can create and manage your listings using
                      the{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      mobile app. Listing creation and management are not
                      available on our website,{" "}
                      <span className={`${styles.primary}`}>
                        hosttravel.org
                      </span>
                      , at this time.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      2. Hosting Options on{" "}
                      <span
                        className={`${styles.subTitle} ${styles.primary} ${styles.bold}`}
                      >
                        HOST
                      </span>
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      As a CAMP Host, you have the opportunity to offer a
                      variety of camping experiences, from gravel lots and
                      concrete pads to lush grass lots. You will also be
                      expected to accommodate at least one of the following
                      types of traveler: automobiles, big rigs, fifth wheels,
                      motorhomes, tents, tent trailers, travel trailers, vans,
                      and other camping preferences. You can also enhance your
                      offerings by providing amenities like power hook-ups (20
                      amp, 30 amp, or 50 amp), water hook-ups, sewage hook-ups,
                      firewood, wifi accessibility, and cable TV access.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      If you choose to be a RODEO Host, you can open your
                      property to travelers with livestock.{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      supports various categories of livestock, including
                      horses, cows, sheep, goats, pigs, and more. You can offer
                      different types of boarding options, such as full boarding
                      (providing full care for the animals), partial boarding
                      (where guests assist with care), pasture boarding (outdoor
                      living with feed and water), and self-care boarding
                      (guests fully care for their animals). Additionally, RODEO
                      Hosts can provide amenities like power hook-ups (20 amps,
                      30 amps, or 50 amps), water, feed, bedding, stalls, and
                      special services (veterinary services, hoof trimming,
                      shearing, shoeing, etc.).
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      3. Hosting Expectations
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Hosts are expected to deliver on the services and
                      amenities they list in their offerings.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      4. Creating Desirable Listings
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      To stand out on{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>
                      , create listings with high-quality, comprehensive photos,
                      stunning locations, a wide range of amenities, and the
                      ability to accommodate the greatest variety of travelers.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      5. Flexible Pricing
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Hosts have the freedom to set their daily prices and offer
                      weekly and monthly discounts. In addition, you can
                      establish Surge prices for special dates (Surge Dates are
                      days where Hosts can set a higher price on their
                      listings), such as national holidays and local events.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      6. Listing Management
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      At this time, hosts can easily manage their listings using
                      the{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      Mobile app. The ability to manage listings on our website,{" "}
                      <span className={`${styles.primary}`}>
                        hosttravel.org
                      </span>
                      , is in development and will be available soon.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      7. Tailoring Your Listing
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Through the{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      Mobile app, hosts have the flexibility to edit and
                      customize every aspect of their listings. You can update
                      property details, amenities, and descriptions, ensuring
                      that your listing showcases the unique features of your
                      hosting space.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      8. Tracking Your Performance
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      The 'Manage My Listings' console on the mobile app
                      provides hosts with valuable insights into their{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      experience. Keep tabs on essential statistics, including
                      your total earnings, the number of stays hosted, and the
                      total nights guests have stayed in your listings.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      9. Calendar Management
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Managing your listing's availability is simple using the
                      'Manage My Calendars' console within the mobile app. Keep
                      your listing's calendar up to date, block off unavailable
                      dates, set Surge Dates, and ensure your property is ready
                      to welcome guests.
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      We are committed to enhancing your hosting experience.
                      Stay tuned for the launch of our listing management
                      features on our website,{" "}
                      <span className={`${styles.primary}`}>
                        hosttravel.org
                      </span>
                      , providing hosts with even more tools and capabilities.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      10. Reservation Management and Guest-Host Communication
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Once a guest reserves your listing, you'll receive
                      notifications via email and the{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      mobile app. However, please note that communication
                      between Hosts and Guests is only possible until the
                      reservation is completed.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      11. Security
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      For everyone's peace of mind, we require all{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      users to complete a security check through Stripe
                      Identity. Implementing surveillance on your property is a
                      valuable security measure.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      12. Resolving Issues
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      In the event of issues or disputes,{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>
                      's support team is here to help. Reach out to{" "}
                      <span className={`${styles.primary}`}>
                        support@hosttravel.org
                      </span>
                      , and we'll work to address and resolve any challenges.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      13. Payment Processing
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      When you sign up with{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>
                      , a Stripe Connect account is automatically created for
                      you. You'll receive your payments through this Stripe
                      Connect account after your reservations are fulfilled.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subTitle} ${styles.bold}`}>
                      14. Coming Soon: Support Resources
                    </span>
                  </div>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      As{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      grows, we are committed to providing more support
                      resources, including guides, tutorials, and a dedicated
                      support team to serve all our users. These resources are
                      designed to enhance your{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>{" "}
                      experience.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      Becoming a Host is your gateway to safe, secure,
                      affordable, and exciting travel opportunities. At{" "}
                      <span className={`${styles.primary} ${styles.bold}`}>
                        HOST
                      </span>
                      , your privacy, safety, and security are our top
                      priorities. We look forward to welcoming you into our
                      community of Hosts and Guests as we work together to
                      create a convenient and thrilling travel experience.
                    </span>
                  </div>
                </div>
                <div className={`${styles.subSection}`}>
                  <div className={`${styles.paragraph}`}>
                    <span className={`${styles.subText}`}>
                      For more information about hosting and the latest updates,
                      please contact our support email at{" "}
                      <span className={`${styles.primary}`}>
                        support@hosttravel.org
                      </span>
                      . Join us on this journey of creating unforgettable travel
                      experiences!
                    </span>
                  </div>
                </div>
              </>
            )}
          </Stack>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
