export default {
  primary: '#f05a28',
  transparentPrimary: '#FFBB80',
  secondary: '#562b00',
  black: '#000',
  white: '#fff',
  light: '#e8e4e4',
  tan: '#e8edd1',
  gray: '#d2d2d2',
  medium: '#5b5b5b',
  mediumGray: '#969696',
  dark: '#0c0c0c',
  offred: '#fbf3f1',
  red: '#ff0000',
  green: '#00D000',
};