import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Carousel } from "../Carousel/Carousel";
import { Storage } from "aws-amplify";
import styles from "./Content.module.css";
import colors from "../../theme/colors";

interface IContent {
  image?: string | null | undefined;
  images?: string[] | null | undefined;
  style: React.CSSProperties;
}

export const Content = ({ image, images, style }: IContent) => {
  const [imageURI, setImageURI] = useState<string | null>(null);
  const [imageURIs, setImageURIs] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    downloadImage();
  }, []);

  const downloadImage = async () => {
    try {
      if (image) {
        const uri = await Storage.get(image);
        setImageURI(uri);
      } else if (images) {
        const uris = await Promise.all(images.map((img) => Storage.get(img)));
        setImageURIs(uris);
      }
    } catch (error) {
      console.error("Error downloading image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center ${style}`}
      >
        <Spinner
          animation="border"
          style={{
            borderColor: colors.primary,
            borderRightColor: "transparent",
          }}
        />
      </div>
    );
  }

  if (imageURI) {
    return <img style={style} src={imageURI} alt="Image" />;
  }

  if (imageURIs) {
    return <Carousel style={style} images={imageURIs} />;
  }

  return <div style={style} className={`${styles.noImage}`} />;
};
