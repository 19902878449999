import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stack, Button } from "react-bootstrap";
import styles from "./SignInPage.module.css";
import logoOrange from "../../../assets/host_logo_negative_orange.svg";
import { FormInput } from "../components/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useAuthContext } from "../../../contexts/AuthContext";

type SignInData = {
  username: string;
  password: string;
};

export const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { setUser } = useAuthContext();

  const { control, handleSubmit, reset } = useForm<SignInData>();

  const onSignInPressed = async ({ username, password }: SignInData) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const cognitoUser = await Auth.signIn(username, password);
      await setUser(cognitoUser);
      navigate("/search");
    } catch (e) {
      if ((e as Error).name === "UserNotConfirmedException") {
        navigate("/confirmemail");
      } else {
        alert(`Ooops, ${(e as Error).message}`);
      }
    } finally {
      setLoading(false);
      reset();
    }
  };

  return (
    <Stack className={`${styles.authContainer} mx-auto`} direction="vertical">
      <img src={logoOrange} alt="H" className={`img-fluid ${styles.logo}`} />

      <FormInput
        name="username"
        placeholder="Username"
        control={control}
        rules={{ required: "Username is required" }}
      />

      <FormInput
        name="password"
        placeholder="Password "
        secureTextEntry
        control={control}
        rules={{
          required: "Password is required",
          minLength: {
            value: 8,
            message: "Password should be minimum 3 characters long",
          },
        }}
      />

      <Button
        className={`${styles.button}`}
        onClick={handleSubmit(onSignInPressed)}
      >
        {loading ? "Loading..." : "SIGN IN"}
      </Button>

      <div className={`${styles.buttonContainer}`}>
        <Link to="/forgotpassword">
          <span className={`${styles.buttonText}`}>Forgot password?</span>
        </Link>
      </div>

      <div className={`${styles.buttonContainer}`}>
        <Link to="/signup">
          <span className={`${styles.buttonText}`}>
            Don't have an account? Create one
          </span>
        </Link>
      </div>
    </Stack>
  );
};
