import React, { useState } from "react";
import { Image, Container, Row, Col } from "react-bootstrap";

interface ICarousel {
  images: string[] | undefined;
  style: React.CSSProperties;
}

export const Carousel = ({ images, style }: ICarousel) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const handleImageClick = (index: any) => {
    setActiveImageIndex(index);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="carousel-container">
            {images &&
              images.map((image, index) => (
                <div
                  key={index}
                  className={`carousel-image ${
                    activeImageIndex === index ? "active" : ""
                  }`}
                >
                  <Image
                    src={image}
                    alt={`Image ${index}`}
                    onClick={() => handleImageClick(index)}
                  />
                </div>
              ))}
          </div>
          <div className="dots-container">
            {images &&
              images.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${
                    activeImageIndex === index ? "active" : ""
                  }`}
                />
              ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};